import { useCallback } from "react";
import { useQuery } from "react-query";
import { User } from "../domains/user.entity";
import userService from "../domains/user.service";
import { showSuccess } from "helpers/toast";

const deleteQueryName = "deleteUser";
interface IUseUpdateUser {
  selectUser: User;
  callback: () => void;
}

export const useDeleteUser = ({ selectUser, callback }: IUseUpdateUser) => {
  const deleteUser = useCallback(async () => {
    if (!selectUser) return;
    if (!selectUser.id) return;

    const res = await userService.deleteUser(selectUser.id);
    showSuccess("Xóa nhân viên thành công.");
    return res;
  }, [selectUser]);

  const { isLoading, refetch } = useQuery([deleteQueryName], () => deleteUser(), {
    onSuccess() {
      callback();
    },
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 2,
  });

  const onDeleteUser = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    deleting: isLoading,
    onDeleteUser,
  };
};
