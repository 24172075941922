import axiosUnicorn from "libs/axios/axiosUnicorn";
import { PositionInterface } from "./position.interface";
import { PositionResponse } from "modules/Core/domains/positions/position.entity";
class PositionService implements PositionInterface {
  async queryPositions(): Promise<PositionResponse[]> {
    const response = await axiosUnicorn.get("unicorn/account-service/positions");

    return response?.data?.data;
  }
}

const positionService = new PositionService();
export default positionService;
