import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Users } from "../../assets/icons/Users.svg";
import { ReactComponent as UserPlus } from "../../assets/icons/UserPlus.svg";
import { ReactComponent as Storefront } from "../../assets/icons/Storefront.svg";
import { ListRoutes } from "../../routes";
import { LayoutContext } from "components/layout/context";
import { useWindowResize } from "utils/useWindowResize";
// import { ReactComponent as User } from "../assets/icons/User.svg";
// import { ReactComponent as Desktop } from "../assets/icons/Desktop.svg";
// import { ReactComponent as LockKeyOpen } from "../assets/icons/LockKeyOpen.svg";
// import { ReactComponent as ShieldCheck } from "../assets/icons/ShieldCheck.svg";
// import { ReactComponent as ArrowsCounterClockwise } from "../assets/icons/ArrowsCounterClockwise.svg";

const menu = [
  {
    name: "Tạo tài khoản",
    icon: <UserPlus />,
    to: ListRoutes.CREATE_USERS,
  },
  {
    name: "Danh sách nhân viên",
    icon: <Users />,
    to: ListRoutes.LIST_USERS,
  },
  // {
  //   name: "Thêm thông tin tài khoản",
  //   icon: <User />,
  //   to: "/"
  // },
  // {
  //   name: "Ứng dụng đã cấp quyền",
  //   icon: <Desktop />,
  //   to: "/"
  // },
  // {
  //   name: "Xác thực 2 bước",
  //   icon: <ShieldCheck />,
  //   to: "/"
  // },
  // {
  //   name: "Lịch sử đăng nhập",
  //   icon: <ArrowsCounterClockwise />,
  //   to: "/"
  // },
  // {
  //   name: "Đổi mật khẩu",
  //   icon: <LockKeyOpen />,
  //   to: "/"
  // },
];

const Nav = ({ classWrapperNav }: any) => {
  const { pathname } = useLocation();
  const { isMobile } = useWindowResize();
  const { isOpenMenu, setIsOpenMenu } = useContext(LayoutContext);

  return (
    <>
      <div className={`${classWrapperNav()} h-full transition-all bg-background-color-sidebar`}>
        <h4
          className={`${
            isOpenMenu ? "block" : "hidden"
          } font-semibold text-sm mb-4 text-title-sidebar`}
        >
          Quản lý tài khoản (C&B)
        </h4>
        <ul>
          {menu.map((item, index) => (
            <li key={index}>
              <Link
                to={item.to}
                onClick={() => {
                  if (isMobile) setIsOpenMenu(false);
                }}
                className={`${
                  pathname !== item.to
                    ? "bg-background-button-sidebar"
                    : "bg-background-button-sidebar-active"
                } ${isOpenMenu ? "gap-2.5 py-2 px-5" : "h-12 items-center justify-center"}
              mb-2 opacity-60 hover:opacity-100 flex items-center rounded-lg hover:bg-background-button-sidebar-active text-white text-sm`}
              >
                {item.icon}
                {isOpenMenu ? item.name : ""}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {isMobile && isOpenMenu && (
        <div
          onClick={() => setIsOpenMenu(!isOpenMenu)}
          className="fixed inset-0 bg-black opacity-60 z-[9]"
        />
      )}
    </>
  );
};

export default Nav;
