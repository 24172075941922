import ReactDOM from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/vi_VN";
import "antd/dist/reset.css";
import "./styles.scss";

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider autoInsertSpaceInButton={false} locale={locale}>
      <App />
    </ConfigProvider>
  </QueryClientProvider>
);
