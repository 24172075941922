import { Modal } from "antd";
import { useCallback } from "react";
import { ReactComponent as Lock } from "../../../assets/icons/Lock.svg";
import { ReactComponent as LockOpen } from "../../../assets/icons/LockOpen.svg";
import { ReactComponent as Trash } from "../../../assets/icons/Trash.svg";
import { ReactComponent as X } from "../../../assets/icons/X.svg";

const { confirm } = Modal;
interface IUseHandleConfirm {
  deleting?: boolean;
  onDeleteUser?: () => void;
  updating?: boolean;
  onUpdateUser?: () => void;
}

export const useHandleConfirm = ({ onDeleteUser, onUpdateUser }: IUseHandleConfirm) => {
  const handelDestroyAllModal = useCallback(() => {
    Modal.destroyAll();
  }, []);

  const showConfirmDeleteAccount = () => {
    confirm({
      title: "Bạn có chắc chắn xóa tài khoản?",
      okText: "Đồng ý",
      cancelText: "Hủy",
      icon: <Trash />,
      content: <X className="close-btn" onClick={handelDestroyAllModal} />,
      okButtonProps: {
        danger: true,
      },
      onOk() {
        onDeleteUser && onDeleteUser();
      },
    });
  };

  const showConfirmLockAccount = () => {
    confirm({
      title: "Bạn có chắc chắn khóa tài khoản?",
      okText: "Đồng ý",
      cancelText: "Hủy",
      icon: <Lock />,
      content: <X className="close-btn" onClick={handelDestroyAllModal} />,
      onOk() {
        onUpdateUser && onUpdateUser();
      },
    });
  };

  const showConfirmUnLockAccount = () => {
    confirm({
      title: "Bạn có chắc chắn mở khóa tài khoản?",
      okText: "Đồng ý",
      cancelText: "Hủy",
      icon: <LockOpen />,
      content: <X className="close-btn" onClick={handelDestroyAllModal} />,
      onOk() {
        onUpdateUser && onUpdateUser();
      },
    });
  };

  return { showConfirmDeleteAccount, showConfirmLockAccount, showConfirmUnLockAccount };
};
