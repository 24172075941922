import { useCallback } from "react";
import { useExportExcel } from "../../../utils/useExportExcel";
import userService from "../domains/user.service";
import { User } from "../domains/user.entity";
import { useQueryClient } from "react-query";

interface IExportUser {
  setIsLoadingExport: (isLoading: boolean) => void;
  usersList?: User[];
  keySearch?: string;
}
export const useExportUser = ({ setIsLoadingExport, usersList, keySearch }: IExportUser) => {
  const { exportExcelFile } = useExportExcel();
  const queryClient = useQueryClient();

  const handleExportUser = useCallback(
    async (exportAll: boolean) => {
      setIsLoadingExport(true);
      const fileName = "Danh_sach_nhan_vien";
      const workSheetColumnName = [
        "Tài khoản",
        "Tên nhấn viên",
        "Số điện thoại",
        "Vị trí làm việc",
        "Nhóm quyền",
        "Cửa hàng",
        "Trạng thái",
      ];

      let allUsers: User[] | any = [];
      if (exportAll) {
        let page = 0;
        while (true) {
          const users = await userService.fetchUserList(page, 50, keySearch);
          page++;
          if (!users || users.length === 0 || page > 400) break;
          allUsers = [...allUsers, ...users];
        }
      } else {
        if (usersList) {
          allUsers = usersList.map((user) => {
            const dataStore: any = queryClient.getQueryData(["fetchRole", user.id]);
            const storeInfos = dataStore?.clientMappings?.["unicorn-location"]?.mappings;
            const dataGroup: any = queryClient.getQueryData(["fetchgroup", user.id]);

            return {
              ...user,
              stores: storeInfos
                ?.map((store: any) => {
                  return store?.description;
                })
                ?.join(", "),
              groups: dataGroup
                ?.map((group: any) => {
                  return group?.name;
                })
                ?.join(", "),
            };
          });
        }
      }

      if (exportAll) {
        for (let i = 0; i < allUsers.length; i++) {
          const user = allUsers[i];
          const dataStore: any = await userService.getUserRoles(user.id || "");
          const storeInfos = dataStore?.clientMappings?.["unicorn-location"]?.mappings;
          const dataGroup: any = await userService.getUserGroups(user.id || "");

          allUsers[i] = {
            ...user,
            stores: storeInfos
              ?.map((store: any) => {
                return store?.description;
              })
              ?.join(", "),
            groups: dataGroup
              ?.map((group: any) => {
                return group?.name;
              })
              ?.join(", "),
          };
        }
      }

      const workSheetColumnData = allUsers?.map((item: any) => {
        return [
          item.username,
          `${item?.lastName || ""} ${item?.firstName || ""}`,
          item?.attributes?.phoneNumber?.[0],
          item?.attributes?.positionTitle?.[0],
          item?.groups,
          item?.stores,
          item?.enabled === true ? "Hoạt động" : "Không hoạt động",
        ];
      });
      exportExcelFile(workSheetColumnName, workSheetColumnData ?? [], fileName, fileName);

      setIsLoadingExport(false);
    },
    [exportExcelFile, keySearch, queryClient, setIsLoadingExport, usersList]
  );

  return { handleExportUser };
};
