import { ApiConst } from "libs/axios/const";
import axiosKeycloak from "../../../libs/axios/axiosKeycloak";
import { Store } from "./store.entity";
import { StoreInterface } from "./store.interface";

class StoreService implements StoreInterface {
  async fetchStoreList(): Promise<Store[]> {
    const response = await axiosKeycloak.get(`/clients/${ApiConst.storeClietnId}/roles`, {
      params: {
        briefRepresentation: false,
      },
    });

    return response?.data || [];
  }

  async countStoreList(search?: string): Promise<number> {
    const params = {
      search: search?.trim(),
    };

    if (!search?.trim()) {
      delete params.search;
    }

    const response = await axiosKeycloak.get(`/clients/${ApiConst.storeClietnId}/roles`, {
      params,
    });

    return response?.data.length || 0;
  }

  async createStore(store: Store): Promise<any> {
    return await axiosKeycloak.post(`/clients/${ApiConst.storeClietnId}/roles`, store);
  }

  async updateStore(store: Store, storeName: string): Promise<any> {
    await await axiosKeycloak.put(`/clients/${ApiConst.storeClietnId}/roles/${storeName}`, store);
  }

  async getStoreByName(storeName: string): Promise<Store> {
    const response = await axiosKeycloak.get(
      `/clients/${ApiConst.storeClietnId}/roles/${storeName}`,
      {
        params: {
          briefRepresentation: false,
        },
      }
    );

    return response?.data;
  }
}

const storeService = new StoreService();
export default storeService;
