import axiosUnicorn from "libs/axios/axiosUnicorn";
import { WardsResponse } from "./wards.entity";
import { WardsInterface } from "./wards.interface";

class WardsService implements WardsInterface {
  async queryWards(districtId?: number): Promise<WardsResponse[] | undefined> {
    if (!districtId) return;
    const response = await axiosUnicorn.get(
      `unicorn/content-service/districts/${districtId}/wards`
    );

    return response?.data?.data;
  }
}

const wardsService = new WardsService();
export default wardsService;
