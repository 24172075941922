import React, { useCallback } from "react";
import { Form, Space, Input, Select, TreeSelect } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useQueryDepartments } from "modules/Core/hooks/useQueryDepartment";
import { useQueryPositions } from "modules/Core/hooks/useQueryPosition";
import TreeDepartment from "./TreeDepartment";
import "./styles.css";
import CustomSelect from "components/CustomFormSelect/CustomSelectHocs";
import { DepartmentResponse } from "modules/Core/domains/departments/department.entity";

const { Item } = Form;
const { Option } = Select;

export const DepartmentList = ({
  disabled,
  form,
  onDepartmentClear,
  onPositionClear,
}: {
  disabled?: boolean;
  form: any;
  onDepartmentClear: () => void;
  onPositionClear: () => void;
}) => {
  const { data: listDepartmentTree } = useQueryDepartments();
  const { data: listPosition } = useQueryPositions();

  const converDepartment = useCallback((value: any, array?: DepartmentResponse[]) => {
    if (!array || !value) return null;
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (item.id === Number(value)) {
        return item.name;
      }
      if (item?.children) {
        const childResult: any = converDepartment(value, item.children);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  }, []);

  return (
    <div className="department-list">
      <div className="department-item-container w-full">
        <div className="flex-1">
          <Item
            label="Phòng ban"
            name={["attributes", "departmentId"]}
            rules={[{ required: true, message: "Vui lòng chọn bộ phận" }]}
          >
            <TreeSelect
              placeholder="Chọn phòng ban"
              treeDefaultExpandAll
              className="custom-tree-department"
              allowClear
              showSearch
              treeNodeFilterProp="title"
              suffixIcon={
                <Space>
                  <div>|</div>
                  <CaretDownOutlined />
                </Space>
              }
              disabled={disabled}
              onClear={onDepartmentClear}
              onChange={(value: string) => {
                form.setFieldsValue({
                  attributes: {
                    departmentName: converDepartment(value, listDepartmentTree),
                  },
                });
              }}
            >
              {listDepartmentTree?.map((item: any, index: any) => (
                <React.Fragment key={index}>{TreeDepartment(item)}</React.Fragment>
              ))}
            </TreeSelect>
          </Item>
          <Form.Item hidden name={["attributes", "departmentName"]}>
            <Input />
          </Form.Item>
        </div>
        <div className="flex-1">
          <Item className="position-form-item" name={["attributes", "positionId"]} label="Vị trí">
            <CustomSelect
              autoClearSearchValue={false}
              placeholder="Chọn vị trí"
              allowClear
              showArrow
              showSearch
              optionFilterProp="children"
              className="custom-select-position"
              disabled={disabled}
              onClear={onPositionClear}
              onChange={(value: string) => {
                form.setFieldsValue({
                  attributes: {
                    positionTitle: listPosition?.filter((item) => item.id == Number(value))?.[0]
                      ?.name,
                  },
                });
              }}
            >
              {listPosition?.map((item: any) => (
                <Option key={item.id} value={item.id.toString()}>
                  {item.name}
                </Option>
              ))}
            </CustomSelect>
          </Item>
          <Form.Item hidden name={["attributes", "positionTitle"]}>
            <Input />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
