export interface StoreByDepartment {
  /** Level trực thuộc */
  [key: string]: string | number;
  /** Id cửa hàng */
  id: number;
  /** Tên cửa hàng */
  name: string;
  /** ID bộ phận tương ứng */
  department_id: number;
  /** Tên bộ phận tương ứng */
  department: string;
}
export interface StoreWithType {
  /** Level trực thuộc */
  [key: string]: string | number;
  /** Id cửa hàng */
  id: number;
  /** Tên cửa hàng */
  name: string;
  /** Loại kho/cửa hàng */
  storeType: string;
  /** Tên loại kho/cửa hàng */
  storeTypeName: string;
}


export enum EnumLocation {
  store = "Cửa hàng & kho",
  ware_house = "Kho online",
  distribution_center = "Kho phân phối",
  stockpile = "Kho dự trữ",
  disposal_warehouse = "Kho hàng hủy",
  hub_store = "Kho HUB",
  unknown = "Chưa xác định"
}