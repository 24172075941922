import { Form, Button, Space, Input, Radio, TreeSelect } from "antd";
import { RoleResponse } from "modules/Core/domains/role/role.entity";
import { useQueryRoles } from "modules/Core/hooks/useQueryRole";
import { useStoresWithType } from "modules/Core/hooks/useQueryStore";
import { useCallback, useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import { ListRoutes } from "routes";
import CustomFormDatePicker from "components/CustomFormDatePicker";
import CustomFormInput from "components/CustomFormInput";
import CustomFormSelect from "components/CustomFormSelect";
import { DepartmentList } from "components/DepartmentList";
import { useValidatePhoneField } from "utils/useValidatePhoneField";
import { EMAIL_REGEX_VALIDATE } from "modules/Store/hooks/useCreateStore";
import { trim } from "lodash";
import { CaretDownOutlined } from "@ant-design/icons";
import CustomFormSwitch from "components/CustomFormSwitch";
import "./styles.scss";
import CustomFormTreeStore from "components/CustomFormTreeStore";
import { StoreWithType } from "modules/Core/domains/core.entity";
import { useQueryCountries } from "modules/Core/hooks/useQueryCountries";

function groupOptions(groups?: RoleResponse[]): any {
  return groups?.map((group: RoleResponse) => ({
    title: group.name,
    value: group.path,
    children: groupOptions(group?.subGroups || []),
  }));
}

const genderOptions = [
  { label: "Nam", value: "male" },
  { label: "Nữ", value: "female" },
  { label: "Khác", value: "other" },
];

const { Item } = Form;
export const UserForm = ({
  form,
  onSubmit,
  isUpdate,
  isServiceAccount,
  districtIdDefault,
  locationSelectionDefault,
}: any) => {
  const { aggregateDataStore } = useStoresWithType();
  const { data: roles } = useQueryRoles();
  const { countries, setCountryId, districts, setDistrictId, wards } = useQueryCountries();
  const [isAllStore, setIsAllStore] = useState(false);

  useEffect(() => {
    if (isAllStore) {
      form.setFieldsValue({
        stores: undefined,
      });
    }
  }, [form, isAllStore]);

  useEffect(() => {
    if (districtIdDefault) {
      setDistrictId(districtIdDefault);
    }
  }, [districtIdDefault, setDistrictId]);

  useEffect(() => {
    if (locationSelectionDefault != null) {
      setIsAllStore(locationSelectionDefault);
    }
  }, [locationSelectionDefault]);

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    const newStore = form.getFieldsValue();
    onSubmit(newStore);
  }, [form, onSubmit]);

  const { normalizePhoneNumber, PHONE_PATTERN } = useValidatePhoneField();

  return (
    <div className="user-form">
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item hidden name={["attributes", "locationSelection"]}>
        <Input />
      </Form.Item>
      <CustomFormInput
        name="username"
        label="Tài khoản/Mã nhân viên"
        placeholder="VD: YD8445"
        rules={[
          { required: true },
          {
            message: "Vui lòng nhập thông tin mã nhân viên",
          },
        ]}
        onBlur={(event: any) => {
          const username = event.target.value;
          form.setFieldsValue({
            username: username.trim().toLowerCase().replaceAll(" ", ""),
          });
        }}
        disabled={isUpdate}
      />
      <CustomFormInput
        name="lastName"
        label="Họ"
        rules={[{ required: true }]}
        placeholder="Nhập họ"
        disabled={isServiceAccount}
      />
      <CustomFormInput
        name="firstName"
        label="Tên"
        rules={[{ required: true }]}
        placeholder="Nhập tên"
        disabled={isServiceAccount}
      />
      <div className="form-group">
        <div className="label-group">
          <label className="ant-form-item-required">Giới tính</label>
        </div>
        <div className="flex gap-10 items-center h-[46px] max-[600px]:flex-col max-[600px]:h-auto max-[600px]:items-start">
          <Form.Item
            name={"gender"}
            rules={[{ required: true, message: "Vui lòng chọn giới tính" }]}
          >
            <Radio.Group disabled={isServiceAccount}>
              {genderOptions?.map((option: any) => {
                return (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <CustomFormInput
        name={["attributes", "phoneNumber"]}
        label="Số điện thoại"
        placeholder="Nhập số điện thoại"
        rules={[
          { required: true, message: "Nhập số điện thoại" },
          {
            pattern: PHONE_PATTERN,
            message: "Vui lòng nhập đúng định dạng SDT",
          },
        ]}
        onBlur={(event: any) => {
          const phoneNumber = event.target.value;
          form.setFieldsValue({
            attributes: {
              phone: normalizePhoneNumber(phoneNumber),
            },
          });
        }}
        disabled={isServiceAccount}
      />
      <Item hidden name={["attributes", "phoneNumberVerified"]}>
        <Input />
      </Item>
      {/* địa chỉ */}
      <CustomFormSelect
        name={["attributes", "countryData"]}
        label="Quốc gia"
        placeholder="Chọn quốc gia"
        disabled={isServiceAccount}
        // rules={[{ required: true, message: "Vui lòng chọn quốc gia" }]}
        options={countries?.map((item) => ({
          label: item.name,
          value: JSON.stringify({
            name: item.name,
            id: item.id.toString(),
          }),
        }))}
        onChange={(value: string) => {
          const data = JSON.parse(value);
          setCountryId(data.id);
          form.setFieldsValue({
            attributes: {
              countryId: data.id,
              countryName: data.name,
              districtData: undefined,
              cityId: undefined,
              cityName: undefined,
              districtId: undefined,
              districtName: undefined,
              wardData: undefined,
              wardId: undefined,
              wardName: undefined,
            },
          });
        }}
      />
      <Item hidden name={["attributes", "countryId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "countryName"]}>
        <Input />
      </Item>
      <CustomFormSelect
        name={["attributes", "districtData"]}
        label="Khu vực"
        placeholder="Chọn khu vực"
        disabled={isServiceAccount}
        // rules={[{ required: true, message: "Vui lòng chọn khu vực" }]}
        options={districts?.map((item) => ({
          label: `${item.city_name} - ${item.name}`,
          value: JSON.stringify({
            cityId: item.city_id.toString(),
            cityName: item.city_name,
            districtId: item.id.toString(),
            districtName: item.name,
          }),
        }))}
        onChange={(value: string) => {
          const data = JSON.parse(value);
          setDistrictId(data.districtId);
          form.setFieldsValue({
            attributes: {
              cityId: data.cityId,
              cityName: data.cityName,
              districtId: data.districtId,
              districtName: data.districtName,
              wardData: undefined,
              wardId: undefined,
              wardName: undefined,
            },
          });
        }}
      />
      <Item hidden name={["attributes", "cityId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "cityName"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "districtId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "districtName"]}>
        <Input />
      </Item>
      <CustomFormSelect
        name={["attributes", "wardData"]}
        label="Phường xã"
        placeholder="Chọn phường xã"
        disabled={isServiceAccount}
        // rules={[{ required: true, message: "Vui lòng chọn phường xã" }]}
        options={wards?.map((item) => ({
          label: item.name,
          value: JSON.stringify({
            name: item.name,
            id: item.id.toString(),
          }),
        }))}
        onChange={(value: string) => {
          const data = JSON.parse(value);
          form.setFieldsValue({
            attributes: {
              wardId: data.id,
              wardName: data.name,
            },
          });
        }}
      />
      <Item hidden name={["attributes", "wardId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "wardName"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "address"]}>
        <Input />
      </Item>
      <Item name="groups" label="Nhóm quyền" rules={[{ required: false }]}>
        <TreeSelect
          disabled={isServiceAccount}
          placeholder="Chọn nhóm quyền"
          className="custom-tree-department"
          treeDefaultExpandAll
          allowClear
          showSearch
          multiple
          treeNodeFilterProp="title"
          suffixIcon={
            <Space>
              <div>|</div>
              <CaretDownOutlined />
            </Space>
          }
          onChange={(values) => {
            form.setFieldsValue({
              groups: values,
            });
          }}
          onClear={() => {
            form.setFieldsValue({
              groups: [],
            });
          }}
          treeData={roles ? groupOptions(roles) : []}
        />
      </Item>
      <CustomFormInput
        disabled={isServiceAccount}
        name="email"
        label="Email"
        placeholder="Nhập email"
        rules={[
          { required: false },
          {
            transform: trim,
            pattern: EMAIL_REGEX_VALIDATE,
            message: "Vui lòng nhập đúng định dạng email",
          },
        ]}
      />
      <div className="form-group">
        <div className="label-group">
          <label className="ant-form-item-required">Email đã xác nhận</label>
        </div>
        <div className="flex gap-10 items-center h-[46px] max-[600px]:flex-col max-[600px]:h-auto max-[600px]:items-start">
          <CustomFormSwitch name={"emailVerified"} label="" disabled={isServiceAccount} />
        </div>
      </div>
      <CustomFormDatePicker
        name={["attributes", "birthday"]}
        label="Ngày sinh"
        placeholder="dd/mm/yyyy"
        disabled={isServiceAccount}
      />
      <div className="form-group">
        <div className="label-group ant-form-item-label">
          <label>Toàn bộ cửa hàng </label>
        </div>
        <div className="flex gap-10 items-center h-[46px] max-[600px]:flex-col max-[600px]:h-auto max-[600px]:items-start">
          <CustomFormSwitch
            name={["attributes", "locationSelectionData"]}
            disabled={isServiceAccount}
            onChange={(value) => {
              setIsAllStore(value);
            }}
          />
        </div>
      </div>
      <CustomFormTreeStore
        name="stores"
        label="Cửa hàng"
        placeholder="Chọn cửa hàng"
        storeData={aggregateDataStore as unknown as StoreWithType[]}
        disabled={isServiceAccount || isAllStore}
        onClear={() => {
          form.setFieldsValue({
            stores: [],
          });
        }}
        treeDefaultExpandedKeys={["all"]}
      />
      <DepartmentList
        disabled={isServiceAccount}
        form={form}
        onDepartmentClear={() => {
          form.setFieldsValue({
            attributes: {
              departmentName: "",
              departmentId: "",
            },
          });
        }}
        onPositionClear={() => {
          form.setFieldsValue({
            attributes: {
              positionTitle: "",
              positionId: "",
            },
          });
        }}
      />
      <Item hidden name={["attributes", "legacyId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "legacyUserId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "workingStatus"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "code"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "nhanhReferenceId"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "createdBy"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "createdName"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "createdDate"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "updatedBy"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "updatedName"]}>
        <Input />
      </Item>
      <Item hidden name={["attributes", "updatedDate"]}>
        <Input />
      </Item>
      <Space className="ml-200 max-[600px]:ml-auto" size={12}>
        <Button type="primary" onClick={handleSubmit} disabled={isServiceAccount}>
          {isUpdate ? "Cập nhật" : "Tạo tài khoản"}
        </Button>
        <Button type="default" onClick={() => redirect(ListRoutes.LIST_USERS)}>
          Hủy
        </Button>
      </Space>
    </div>
  );
};
