import axiosUnicorn from "libs/axios/axiosUnicorn";
import { DepartmentResponse } from "./department.entity";
import { DepartmentInterface } from "./department.interface";
class DepartmentService implements DepartmentInterface {
  async queryDepartments(): Promise<DepartmentResponse[]> {
    const response = await axiosUnicorn.get("unicorn/account-service/departments");

    return response?.data?.data;
  }
}

const departmentService = new DepartmentService();
export default departmentService;
