import { ApiConst } from "libs/axios/const";
import axiosKeycloak from "../../../libs/axios/axiosKeycloak";
import { Group } from "./group.entity";
import { ResetPassworDto, User } from "./user.entity";
import { UserInterface } from "./user.interface";
import { Store } from "modules/Store/domains/store.entity";
class UserService implements UserInterface {
  async createUser(user: any, storeIds: string[], stores?: Store[]) {
    const res: any = await axiosKeycloak.post("users", user);
    const userId = res?.responseLocation.split("/").pop();
    await this.assignRoleToUser(userId, storeIds, undefined, stores);

    return res;
  }

  async resetPassword(userId: string, resetPassworDto: ResetPassworDto) {
    await axiosKeycloak.put(`users/${userId}/reset-password`, resetPassworDto);
  }

  async assignRoleToUser(
    userId: string,
    storeIds?: string[],
    oldStores?: string[],
    stores?: any[]
  ) {
    if (!storeIds || !stores) return;

    if (oldStores && oldStores?.length > 0) {
      const removeRoles = stores
        .filter((store) => oldStores.includes(store.id.toString()))
        .map((store) => ({
          id: store?.id,
          name: store?.name,
        }));

      await axiosKeycloak.delete(
        `users/${userId}/role-mappings/clients/${ApiConst.storeClietnId}`,
        {
          data: [...removeRoles],
        }
      );
    }

    if (storeIds && storeIds?.length > 0) {
      const roles = stores.filter((store) => storeIds.includes(store.id.toString()));

      await axiosKeycloak.post(`users/${userId}/role-mappings/clients/${ApiConst.storeClietnId}`, [
        ...roles,
      ]);
    }
  }

  async assignGroupsToUser(
    userId: string,
    groups: string[],
    oldGroups: string[],
    groupInfos: Group[]
  ) {
    const addedGroups = groups.filter((groupId) => !oldGroups.includes(groupId));
    const removedGroups = oldGroups.filter((groupId) => !groups.includes(groupId));

    const groupPathMap: any = {};
    const getIds = (groups: any) => {
      groups.forEach((group: any) => {
        groupPathMap[group.path] = group.id; // print the id of the current group
        if (group.subGroups.length > 0) {
          getIds(group.subGroups); // recursively call the function for the sub-groups
        }
      });
    };
    getIds(groupInfos);

    const addedGroupIds = addedGroups.map((groupPath) => groupPathMap[groupPath]);
    const removedGroupIds = removedGroups.map((groupPath) => groupPathMap[groupPath]);

    addedGroupIds.forEach(async (groupId) => {
      await axiosKeycloak.put(`/users/${userId}/groups/${groupId}`);
    });

    removedGroupIds.forEach(async (groupId) => {
      await axiosKeycloak.delete(`/users/${userId}/groups/${groupId}`);
    });
  }

  async updateUser(user: Partial<User>) {
    return await axiosKeycloak.put(`users/${user.id}`, user);
  }

  async fetchUserList(page: number, limit: number, search?: string): Promise<User[]> {
    const params = {
      briefRepresentation: false,
      first: page * limit,
      max: limit,
      search: search?.trim(),
    };

    if (!search?.trim()) {
      delete params.search;
    }

    const userData = await axiosKeycloak.get("admin-ui-brute-force-user", {
      params,
    });

    return userData?.data || [];
  }
  async countUserList(search?: string): Promise<number> {
    const userData = await axiosKeycloak.get("users/count", {
      params: {
        search: search?.trim(),
      },
    });

    return userData?.data || 0;
  }

  async getUserById(userId: string): Promise<any> {
    const res = await axiosKeycloak.get(`users/${userId}`);
    return res?.data;
  }

  async getUserRoles(userId: string): Promise<any> {
    const res = await axiosKeycloak.get(`users/${userId}/role-mappings`);
    return res?.data;
  }

  async getUserGroups(userId: string): Promise<Group[]> {
    const res = await axiosKeycloak.get(`users/${userId}/groups`);
    return res?.data;
  }

  async deleteUser(userId: string): Promise<number> {
    return await axiosKeycloak.delete(`users/${userId}`);
  }
}

const userService = new UserService();
export default userService;
