const PHONE_PATTERN = /((\+84|0|84){1}(9|8|7|5|3)){1}([0-9]{8})\b/g;
export const useValidatePhoneField = () => {
  const normalizePhoneNumber = (value: string) => {
    if (value) {
      const phoneNumber = value.replace(/^\+84|^84/, "0");
      return `${phoneNumber}`;
    }
    return value;
  };

  return { normalizePhoneNumber, PHONE_PATTERN };
};
