import { useCallback, useMemo } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import userService from "../domains/user.service";
import { Group } from "../domains/group.entity";

export const getUserByIdQueryName = "getUserById";
export const getUserRolesQueryName = "getUserRoles";
export const getUserGroupsQueryName = "getUserGroups";

const queryOptions = {
  cacheTime: 0,
  staleTime: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  keepPreviousData: false,
  retry: 2,
};

export const useGetUserById = ({ userId }: { userId?: string }) => {
  const { initialized } = useKeycloak();

  const getUserById = useCallback(() => {
    if (userId) return userService.getUserById(userId);
  }, [userId]);

  const getUserGroups = useCallback(() => {
    if (userId) return userService.getUserGroups(userId);
  }, [userId]);

  const getUserRoles = useCallback(() => {
    if (userId) return userService.getUserRoles(userId);
  }, [userId]);

  const { data: userInfo } = useQuery(getUserByIdQueryName, {
    queryFn: () => getUserById(),
    enabled: initialized,
    ...queryOptions,
  });

  const { data: groups } = useQuery(getUserGroupsQueryName, {
    queryFn: () => getUserGroups(),
    enabled: initialized,
    ...queryOptions,
  });

  const { data: roles } = useQuery(getUserRolesQueryName, {
    queryFn: () => getUserRoles(),
    enabled: initialized,
    ...queryOptions,
  });

  const aggregateData = useMemo(() => {
    const clientMappings = roles?.clientMappings;
    const stores: string[] = [];
    if (clientMappings) {
      const storeInfos = clientMappings["unicorn-location"]?.mappings;
      storeInfos &&
        storeInfos.forEach((storeInfo: any) => {
          stores.push(storeInfo.id);
        });
    }

    const groupInfos = groups?.map((group: Group) => group.path);

    return {
      ...userInfo,
      stores,
      groups: groupInfos,
      departments: userInfo?.attributes?.departments
        ? JSON.parse(userInfo?.attributes?.departments)
        : [
            {
              id: "",
            },
          ],
    };
  }, [roles, groups, userInfo]);

  return aggregateData;
};
