import { TreeSelect, TreeSelectProps, Space } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { strForSearch } from "helpers";
import { StoreWithType } from "modules/Core/domains/core.entity";
import { DataNode } from "rc-tree-select/lib/interface";
import { forwardRef, Ref, useMemo } from "react";
import { getStoreDataGroupByStoreType } from "./helper";

type Props = TreeSelectProps<any> & {
  storeData: StoreWithType[] | unknown[];
};

const TreeStore = forwardRef((props: Props, ref: Ref<any>) => {
  const { storeData, ...restProps } = props;
  const storeByDepartmentTree: DataNode[] = useMemo(() => {
    if (!storeData) return [] as DataNode[];
    const store: DataNode[] = getStoreDataGroupByStoreType(storeData);
    return [
      {
        title: "Tất cả",
        key: "all",
        value: "all",
        children: store,
      },
    ];
  }, [storeData]);

  return (
    <TreeSelect
      suffixIcon={
        <Space>
          <div>|</div>
          <CaretDownOutlined />
        </Space>
      }
      ref={ref}
      treeData={storeByDepartmentTree}
      filterTreeNode={(input: String, option: any) => {
        if (option.value) {
          return strForSearch(option.title)?.includes(strForSearch(input));
        }

        return false;
      }}
      {...restProps}
    />
  );
});
TreeStore.defaultProps = {
  placeholder: "Chọn",
  showArrow: true,
  treeDefaultExpandAll: true,
  allowClear: true,
  showSearch: true,
  style: { width: "100%" },
  treeCheckable: true,
  treeNodeFilterProp: "title",
  maxTagCount: "responsive",
};
export default TreeStore;
