/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from "axios";
import { showError } from "helpers/toast";
import { ApiConst } from "./const";
import { HttpStatus } from "./types/unicorn";

export interface AdminResponse<D = {}> {
  data?: D;
  status?: number;
  message?: string;
}

export const REQUEST_TIMEOUT = 30000;
export const defaultHeaders = {
  "Content-Type": "application/json",
};

const onRequest = async (req: any) => {
  const kcToken = localStorage.getItem("kcToken");

  return {
    ...req,
    headers: {
      Authorization: kcToken ? `Bearer ${kcToken}` : undefined,
    },
  };
};

const onRequestError = async (error: any) => {
  throw error;
};

const onResponseError = async (error: any) => {
  throw error;
};

const onResponse = async (res: AxiosResponse): Promise<any> => {
  if (res.data.code === HttpStatus.UNAUTHORIZED) return showError("Lỗi xác thực");
  switch (res.data.code) {
    case HttpStatus.FORBIDDEN:
      showError("Bạn không đủ quyền truy cập, vui lòng liên hệ với IT để được cấp quyền.");
      return;
    case HttpStatus.BAD_GATEWAY:
      showError(
        "Hệ thống đang gián đoạn, vui lòng thử lại sau 5 phút hoặc liên hệ với IT để được hỗ trợ kịp thời."
      );
      return;
    case HttpStatus.UNAUTHORIZED:
      showError("Lỗi xác thực.");
      return;
    default:
      break;
  }
  return {
    data: res.data,
  };
};

export const createRequest = (
  headers: Partial<AxiosRequestHeaders>,
  configs?: AxiosRequestConfig<any>
) => {
  const instance = axios.create({
    baseURL: `${ApiConst.unicornUrl}`,
    headers,
    timeout: REQUEST_TIMEOUT,
    ...configs,
  });

  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, (error) => onResponseError(error));

  return instance;
};

const axiosUnicorn = createRequest(defaultHeaders);

export default axiosUnicorn;
