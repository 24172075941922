import axiosUnicorn from "libs/axios/axiosUnicorn";
import { DistrictsResponse } from "./districts.entity";
import { DistrictsInterface } from "./districts.interface";

class DistrictsService implements DistrictsInterface {
  async queryDistricts(countriesId: string | number): Promise<DistrictsResponse[]> {
    const response = await axiosUnicorn.get(
      `unicorn/content-service/countries/${countriesId}/districts`
    );

    return response?.data?.data;
  }
}

const districtsService = new DistrictsService();
export default districtsService;
