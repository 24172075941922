import { useState, useCallback } from "react";
import { User } from "./../domains/user.entity";
import { useQuery, useQueryClient } from "react-query";
import userService from "../domains/user.service";
import { fetchQueryName, useFetchUserList } from "./useFetchUserList";
import { showSuccess } from "helpers/toast";

const updateQueryName = "updateManyUser";

export const useSelectedUsers = () => {
  const queryClient = useQueryClient();
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [newValue, setNewValue] = useState<Partial<User>>({});
  const { data: usersList, pagination, limit, keySearch } = useFetchUserList();

  const updateUser = useCallback(async () => {
    if (selectedUser.length <= 0) return;

    try {
      const newDatas = usersList
        ?.filter((user) => selectedUser.includes(user?.id || ""))
        ?.map((user) => {
          return { ...user, ...newValue };
        });

      newDatas?.forEach(async (user: User) => {
        const res = await userService.updateUser({ ...user, ...newValue });
        return res;
      });

      queryClient.setQueryData([fetchQueryName, pagination, limit, keySearch?.current], () => {
        return usersList?.map((itemUser) => {
          if (selectedUser.includes(itemUser?.id || "")) return { ...itemUser, ...newValue };
          return itemUser;
        });
      });
      showSuccess("Cập nhật thông tin tài khoàn nhân viên thành công.");
      setSelectedUser([]);
    } catch (error) {}
  }, [keySearch, limit, newValue, pagination, queryClient, selectedUser, usersList]);

  const { isLoading, refetch } = useQuery(updateQueryName, updateUser, {
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 0,
  });

  const onUpdateManyUser = useCallback(() => {
    refetch();
  }, [refetch]);

  return { selectedUser, setSelectedUser, isLoading, onUpdateManyUser, setNewValue, newValue };
};
