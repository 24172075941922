import { Select, Space } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

const withCustomSelect = (WrapperComponent: any) => {
  const EnhancedComponent = ({ ...props }) => {
    return (
      <WrapperComponent
        {...props}
        suffixIcon={
          <Space>
            <div>|</div>
            <CaretDownOutlined />
          </Space>
        }
      />
    );
  };

  return EnhancedComponent;
};

export default withCustomSelect(Select);
