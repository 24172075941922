import { Button, Divider, Modal } from "antd";
import React, { useState, useCallback, ReactNode, useMemo } from "react";

interface IConfirmModal {
  onConfirm: () => void;
  onCancel: () => void;
  content: JSX.Element;
  title: string;
  isLoading: boolean;
  confirmButtonText?: string;
  hideCancelButton?: boolean;
}
export const useConfirmModal = ({
  onConfirm,
  onCancel,
  content,
  title,
  isLoading,
  confirmButtonText,
  hideCancelButton,
}: IConfirmModal) => {
  const [openModal, setOpenModal] = useState(false);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
  }, [onConfirm]);

  const showModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const ConfirmModal = useMemo(() => {
    if (openModal) {
      return (
        <Modal
          width="600px"
          open={openModal}
          title={
            <div>
              <div>{title}</div>
              <Divider />
            </div>
          }
          okText="Xuất dữ liệu"
          cancelText="Đóng"
          onCancel={onCancel}
          footer={
            <div style={{ width: "100%" }}>
              <Divider />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button key="ok" type="primary" onClick={() => handleConfirm()} loading={isLoading}>
                  {!isLoading ? (confirmButtonText ? confirmButtonText : "Xác nhận") : "Đang xử lí"}
                </Button>
                {!hideCancelButton && (
                  <Button
                    className="create-button-custom ant-btn-outline fixed-button"
                    onClick={() => handleCancel()}
                    type="default"
                  >
                    Hủy
                  </Button>
                )}
              </div>
            </div>
          }
        >
          {content}
        </Modal>
      );
    }
    return <></>;
  }, [
    confirmButtonText,
    content,
    handleCancel,
    handleConfirm,
    hideCancelButton,
    isLoading,
    onCancel,
    openModal,
    title,
  ]);

  return { showModal, hideModal, ConfirmModal, openModal };
};
