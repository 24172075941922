import { useKeycloak } from "@react-keycloak/web";
import { useCallback } from "react";
import { useQuery } from "react-query";
import userService from "../domains/user.service";

const countListUsers = "countListUsers";

export const useCountUserList = (keySearch: any) => {
  const { initialized } = useKeycloak();

  const { data, refetch } = useQuery([countListUsers, keySearch?.current], {
    queryFn: () => userService.countUserList(keySearch?.current),
    enabled: initialized,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 2,
  });

  const handleCountUser = useCallback(() => {
    refetch();
  }, [refetch]);

  return { totalUsers: data ?? 0, handleCountUser };
};
