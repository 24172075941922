import * as XLSX from "xlsx";

export const useExportExcel = () => {
  const exportExcelFile = (
    workSheetColumnName: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    workSheetColumnData: any[],
    fileName?: string,
    workSheetName?: string
  ) => {
    const today = new Date();
    const suffix = `${today.getDate()}_${today.getMonth()}_${today.getFullYear()}`;
    const _fileName = `${fileName || "export_file"}_${suffix}.xlsx`;
    const _workSheetName = workSheetName || "";

    const workBook = XLSX.utils.book_new();
    const workSheetData = [workSheetColumnName, ...workSheetColumnData];
    const workSheet = XLSX.utils.aoa_to_sheet(workSheetData);

    XLSX.utils.book_append_sheet(workBook, workSheet, _workSheetName);

    XLSX.writeFile(workBook, _fileName);
  };

  return { exportExcelFile };
};
