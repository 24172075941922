import { User } from "./../domains/user.entity";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { showError, showSuccess } from "helpers/toast";
import userService from "../domains/user.service";
import { useQueryStores } from "modules/Core/hooks/useQueryStore";
import { useQueryRoles } from "modules/Core/hooks/useQueryRole";
import moment from "moment";

export const useUpdateFormUser = () => {
  const { data: stores } = useQueryStores();
  const { data: groups } = useQueryRoles();
  const queryClient = useQueryClient();
  const userInfo: any = queryClient.getQueryData(["fetchUserInfo"]);

  const groupInfos = useCallback(() => {
    if (!groups) return [];

    return groups?.map((item) => ({
      id: item.id,
      name: item.name,
      path: item.path,
      subGroups: item.subGroups,
    }));
  }, [groups]);

  const formatUser = (user: any, isUpdate?: boolean) => {
    const {
      username,
      email,
      firstName,
      lastName,
      attributes,
      emailVerified,
      id,
      gender,
      stores,
      groups,
    } = user;

    const { locationSelectionData } = user?.attributes;

    const now = moment();
    const formattedDate = now.format("YYYY-MM-DD HH:mm:ss");

    let newUser: any = {
      id,
      username,
      email,
      firstName,
      lastName,
      attributes: {
        ...attributes,
        gender: [gender],
        locationSelection: locationSelectionData ? "ALL" : "SPEC",
        updatedBy: userInfo?.preferred_username,
        updatedName: userInfo?.name,
        updatedDate: formattedDate,
      },
      emailVerified: emailVerified,
      enabled: true,
    };

    if (isUpdate) {
      newUser = {
        ...newUser,
        stores,
        groups,
      };
    }

    // If email isn't readable format then auto verify email
    if (newUser.email.startsWith("id+")) {
      newUser.emailVerified = true;
    }

    const fieldDelete = [
      "departmentData",
      "positionData",
      "wardData",
      "districtData",
      "countryData",
      "locationSelectionData",
    ];
    fieldDelete.forEach((field) => {
      delete newUser["attributes"][field];
    });

    const { attributes: attr } = newUser;
    const newAttr: any = {};
    Object.keys(attr).forEach((key: any) => {
      const keyName = key;
      if (!Array.isArray(attr[keyName])) {
        newAttr[keyName] = attr?.[keyName] ? [attr?.[keyName]] : [""];
      } else {
        newAttr[keyName] = attr?.[keyName];
      }
    });

    newUser = {
      ...newUser,
      attributes: newAttr,
    };

    return newUser;
  };

  const updateFormUser = useCallback(
    async (user: any, oldUser: any) => {
      const { stores: storeIds, groups, id } = user;
      const { groups: oldGroups, stores: oldStores } = oldUser;
      const newUser = formatUser(user);
      await userService.updateUser(newUser);
      await userService.assignRoleToUser(id, storeIds, oldStores, stores);
      await userService.assignGroupsToUser(id, groups, oldGroups, groupInfos());
    },
    [groupInfos, stores]
  );

  const mutation = useMutation(
    ({ user, oldUser }: { user: User; oldUser: object; callback: (user: any) => void }) => {
      return updateFormUser(user, oldUser);
    },
    {
      onSuccess: (data, { user, oldUser, callback }) => {
        const newUser = formatUser(user, true);
        callback(newUser);
        showSuccess("Cập nhật thông tin người dùng thành công");
      },
      onError: () => {
        showError("Có lỗi xảy ra");
      },
    }
  );

  return { mutation };
};
