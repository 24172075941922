import withFormItem from "../CustomFormItem/withFormItem";
import TreeStore from "../TreeStore";
import "./styles.css";

const withFormTreeStore = (WrapperComponent: any) => {
  const EnhacedComponent = ({ ...props }) => {
    return (
      <div className="custom-tree-store">
        <WrapperComponent {...props}></WrapperComponent>
      </div>
    );
  };

  return EnhacedComponent;
};

const CustomFormTreeStore = withFormTreeStore(withFormItem(TreeStore));
export default CustomFormTreeStore;
