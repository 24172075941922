import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useWindowResize } from "utils/useWindowResize";

export type TLayoutContext = {
  isOpenMenu: boolean;
  setIsOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export const LayoutContext = createContext<TLayoutContext>({
  isOpenMenu: false,
  setIsOpenMenu: () => {},
});

interface ILayoutContextViewProps {
  children: React.ReactNode;
}

const LayoutContextView = ({ children }: ILayoutContextViewProps) => {
  const { isTablet } = useWindowResize();
  const [isOpenMenu, setIsOpenMenu] = useState(true);

  useEffect(() => {
    if (isTablet) setIsOpenMenu(false);
    else setIsOpenMenu(true);
  }, [isTablet]);

  const ctx = useMemo(() => {
    return {
      isOpenMenu,
      setIsOpenMenu,
    };
  }, [isOpenMenu]);

  return <LayoutContext.Provider value={ctx}>{children}</LayoutContext.Provider>;
};

export default LayoutContextView;
