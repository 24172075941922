import { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import CountriesService from "../domains/countries/countries.service";
import DistrictsService from "../domains/districts/districts.service";
import WardsService from "../domains/wards/wards.service";

export const defaultCountryId = "233";
export const defaultCountryName = "VIETNAM";
export const useQueryCountries = () => {
  const { initialized } = useKeycloak();
  const [countryId, setCountryId] = useState(defaultCountryId);
  const [districtId, setDistrictId] = useState<string | undefined>();

  const { data: countries } = useQuery("countries", {
    queryFn: () => CountriesService.queryCountries(),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: districts } = useQuery(["district", countryId], {
    queryFn: () => DistrictsService.queryDistricts(countryId),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: wards } = useQuery(["district", districtId], {
    queryFn: () => WardsService.queryWards(Number(districtId)),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return { countries, setCountryId, districts, setDistrictId, wards, districtId };
};
