import axiosKeycloak from "libs/axios/axiosKeycloak";
import { RoleInterface } from "./role.interface";
import { RoleAuthorize } from "modules/Core/domains/role/role.entity";

class RoleService implements RoleInterface {
  async queryRoles(): Promise<RoleAuthorize[]> {
    const response = await axiosKeycloak.get("/admin-ui-groups");

    return response.data;
  }
}
const roleService = new RoleService();
export default roleService;
