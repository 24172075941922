import { ColumnsType } from "antd/es/table";
import { User } from "../../User/domains/user.entity";
import { useCallback, useMemo } from "react";
import { GroupActionUser } from "modules/User/components/GroupActionUser";
import { PaginationProps, Popover, Tag } from "antd";
import { ReactComponent as Prev } from "../../../assets/icons/Prev.svg";
import { ReactComponent as Next } from "../../../assets/icons/Next.svg";
import { ReactComponent as DotsThreeOutline } from "../../../assets/icons/DotsThreeOutline.svg";
import { ReactComponent as LockKey } from "../../../assets/icons/LockKey.svg";
import { ReactComponent as Point } from "../../../assets/icons/Point.svg";
import { ListRoutes } from "routes";
import { Link } from "react-router-dom";
import userService from "modules/User/domains/user.service";
import { useQuery } from "react-query";
import Loading from "components/Loading";
import { useWindowResize } from "utils/useWindowResize";

interface IUseConfigTable {
  pagination: number;
  totalUsers: number;
  limit: number;
  handleQueryUser: () => void;
  listUser?: User[];
}
export const useConfigTable = ({
  pagination,
  totalUsers,
  limit,
  handleQueryUser,
  listUser,
}: IUseConfigTable) => {
  const { isMobile } = useWindowResize();
  const startUser = pagination * limit;
  const indexUserStart = useMemo(() => startUser + 1, [startUser]);
  const indexUserEnd = useMemo(() => startUser + limit, [limit, startUser]);

  const renderTag = useCallback((enabled: boolean) => {
    if (enabled) {
      return (
        <div className="inline-flex items-center justify-center content-center gap-2 px-2 py-0.5 rounded-md bg-active text-active-text">
          <Point /> Hoạt động
        </div>
      );
    }

    return (
      <div className="text-title-page inline-flex items-center content-center gap-2 px-2 py-0.5 rounded-md bg-border-color">
        <LockKey /> Đang khóa{" "}
      </div>
    );
  }, []);

  const isServiceAccount = useCallback((username: any) => {
    return username?.startsWith("service-account");
  }, []);

  const renderAction = useCallback(
    (user: User, callback: () => void, onSuccess: () => void) => {
      if (isServiceAccount(user.username)) {
        return (
          <button className="cursor-not-allowed btn-action flex items-center w-[30px] h-[30px] rounded-full justify-center bg-background-input-search hover:bg-background-button-primary border border-border-color">
            <DotsThreeOutline />
          </button>
        );
      }
      return (
        <Popover
          placement={isMobile ? "topLeft" : "top"}
          content={
            <GroupActionUser
              user={user}
              handleQueryUser={handleQueryUser}
              listUser={listUser}
              callback={callback}
              onSuccess={onSuccess}
            />
          }
          trigger="click"
        >
          <button className="btn-action flex items-center w-[30px] h-[30px] rounded-full justify-center bg-background-input-search hover:bg-background-button-primary border border-border-color">
            <DotsThreeOutline />
          </button>
        </Popover>
      );
    },
    [handleQueryUser, isMobile, isServiceAccount, listUser]
  );

  const itemRender: PaginationProps["itemRender"] = (page, type, originalElement) => {
    if (type === "prev") {
      return (
        <button className="flex items-center justify-center m-0 w-full h-full rounded">
          <Prev />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button className="flex items-center justify-center m-0 w-full h-full rounded">
          <Next />
        </button>
      );
    }
    if (type === "page") {
      return (
        <button
          className={`hover:text-white hover:bg-background-button-sidebar-active flex items-center justify-center m-0 w-full h-full rounded`}
        >
          {page}
        </button>
      );
    }
    return originalElement;
  };

  const renderTotal = useCallback(() => {
    return (
      <p className="font-normal">
        {indexUserStart}-{indexUserEnd < totalUsers ? indexUserEnd : totalUsers} trong số{" "}
        {totalUsers}
      </p>
    );
  }, [indexUserEnd, indexUserStart, totalUsers]);

  const columns = useCallback(
    (callback: () => void, onSuccess: () => void): ColumnsType<User> => {
      return [
        {
          title: <span>Tài khoản</span>,
          dataIndex: "username",
          fixed: "left",
          width: 200,
          render: (username: string, user: User) => (
            <Link to={`${ListRoutes.UPDATE_USERS}/${user.id}`} className="font-semibold underline">
              {username}
            </Link>
          ),
        },
        {
          title: <span>Tên nhân viên</span>,
          key: "operation",
          fixed: "left",
          width: 200,
          render: (user: User) => <span>{`${user?.lastName || ""} ${user?.firstName || ""}`}</span>,
        },
        {
          title: <span>Số điện thoại</span>,
          dataIndex: "attributes",
          width: 150,
          render: (attributes) => (
            <div className="flex items-center gap-1 flex-wrap">
              {attributes?.phoneNumber?.map((item: string, index: number) => (
                <span key={index} className="hover:underline">
                  {item}
                </span>
              ))}
            </div>
          ),
        },
        {
          title: <span>Vị trí làm việc</span>,
          dataIndex: "attributes",
          width: 250,
          render: (attributes) => (
            <div className="flex items-center gap-1 flex-wrap">
              <span>{attributes?.positionTitle?.[0]}</span>
            </div>
          ),
        },
        {
          title: <span>Nhóm quyền</span>,
          key: "operation",
          width: 300,
          render: (user: User) => (
            <div className="flex items-center gap-1 flex-wrap">
              <ShowGroup userId={user?.id} />
            </div>
          ),
        },
        {
          title: <span>Cửa hàng</span>,
          key: "operation",
          render: (user: User) => (
            <div className="flex items-center gap-1 flex-wrap">
              <ShowRole
                userId={user?.id}
                locationSelection={user?.attributes?.locationSelection?.[0]}
              />
            </div>
          ),
        },
        {
          title: <span>Trạng thái</span>,
          dataIndex: "enabled",
          width: 150,
          fixed: "right",
          render: renderTag,
        },
        {
          title: "",
          key: "operation",
          width: 55,
          fixed: "right",
          render: (user: User) => renderAction(user, callback, onSuccess),
        },
      ];
    },
    [renderAction, renderTag]
  );

  const columnsMobile = useCallback(
    (callback: () => void, onSuccess: () => void): ColumnsType<User> => {
      return [
        {
          title: (
            <div className="flex items-center justify-between">
              <strong className="font-semibold">Chọn tất cả</strong>
              {renderTotal()}
            </div>
          ),
          key: "operation",
          render: (user: User) => (
            <div className="flex flex-col gap-1 relative">
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Tài khoản:</span>
                <strong className="font-semibold">
                  <Link
                    to={`${ListRoutes.UPDATE_USERS}/${user.id}`}
                    className="font-semibold underline"
                  >
                    {user?.username}
                  </Link>
                </strong>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Tên nhân viên:</span>
                <span className="text-title-page">
                  {`${user?.lastName || ""} ${user?.firstName || ""}`}
                </span>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Số điện thoại</span>
                <div className="flex items-center gap-1 flex-wrap">
                  {user?.attributes?.phoneNumber?.map((item?: string) => (
                    <span key={item} className="hover:underline text-title-page">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Vị trí làm việc:</span>
                <div className="flex items-center gap-1 flex-wrap">
                  <span className="hover:underline text-title-page">
                    <span>{user?.attributes?.positionTitle?.[0]}</span>
                  </span>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Nhóm quyền:</span>
                <div className="flex items-center gap-1 flex-wrap">
                  <ShowGroup userId={user?.id} />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Cửa hàng:</span>
                <div className="flex items-center gap-1 flex-wrap">
                  <ShowRole
                    userId={user?.id}
                    locationSelection={user?.attributes?.locationSelection?.[0]}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <span className="text-title-mobile">Trạng thái:</span>
                <span>{renderTag(!!user?.enabled)}</span>
              </div>
              <>{renderAction(user, callback, onSuccess)}</>
            </div>
          ),
        },
      ];
    },
    [renderAction, renderTag, renderTotal]
  );

  return { columns, columnsMobile, renderTotal, itemRender };
};

const ShowGroup = ({ userId }: any) => {
  const { isMobile } = useWindowResize();

  const getUserGroups = useCallback(() => {
    const res = userService.getUserGroups(userId);
    return res;
  }, [userId]);

  const { data, isLoading } = useQuery(["fetchgroup", userId], {
    queryFn: () => getUserGroups(),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  if (isLoading) return <Loading />;

  return (
    <>
      {data?.map((item: any, key: number) => {
        if (key < 2)
          return (
            <Tag color="blue" key={key} style={{ margin: 0 }}>
              {item.name}
            </Tag>
          );
        return null;
      })}
      {data && data?.length > 2 ? (
        <Popover
          placement={isMobile ? "topRight" : "topLeft"}
          overlayStyle={{ maxWidth: isMobile ? "70vw" : "unset" }}
          content={
            <div className="flex flex-wrap max-w-md max-h-60 overflow-auto gap-1">
              {data?.slice(2)?.map((item: any, key: number) => {
                return (
                  <Tag color="blue" key={key} style={{ margin: 0 }}>
                    {item.name}
                  </Tag>
                );
              })}
            </div>
          }
          trigger="click"
        >
          <Tag color="blue" style={{ margin: 0 }}>
            + {data?.length - 2}
          </Tag>
        </Popover>
      ) : null}
    </>
  );
};

const ShowRole = ({ userId, locationSelection }: any) => {
  const { isMobile } = useWindowResize();
  const getUserRoles = useCallback(() => {
    const res = userService.getUserRoles(userId);
    return res;
  }, [userId]);

  const { data, isLoading } = useQuery(["fetchRole", userId], {
    queryFn: () => getUserRoles(),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  if (isLoading) return <Loading />;

  const storeInfos = data?.clientMappings?.["unicorn-location"]?.mappings;

  return (
    <>
      {locationSelection === "ALL" ? (
        <Tag color="blue" style={{ margin: 0 }}>
          Tất cả cửa hàng
        </Tag>
      ) : null}
      {storeInfos?.map((storeInfo: any, key: number) => {
        if (key < 2)
          return (
            <Tag color="blue" key={key} style={{ margin: 0 }}>
              {storeInfo.description}
            </Tag>
          );

        return null;
      })}
      {storeInfos && storeInfos?.length > 2 ? (
        <Popover
          placement={isMobile ? "topRight" : "topLeft"}
          overlayStyle={{ maxWidth: isMobile ? "70vw" : "unset" }}
          content={
            <div className="flex flex-wrap max-w-md max-h-60 overflow-auto gap-1">
              {storeInfos?.slice(2)?.map((item: any, key: number) => {
                return (
                  <Tag color="blue" key={key} style={{ margin: 0 }}>
                    {item.description}
                  </Tag>
                );
              })}
            </div>
          }
          trigger="click"
        >
          <Tag color="blue" style={{ margin: 0 }}>
            + {storeInfos?.length - 2}
          </Tag>
        </Popover>
      ) : null}
    </>
  );
};
