import React, { useCallback, useContext, useLayoutEffect, useRef, useState } from "react";
import Header from "./Header";
import Nav from "./Nav";
import Context, { LayoutContext } from "./context";
import { useWindowResize } from "utils/useWindowResize";
import useUpdateKCToken from "hooks/useUpdateToken";

interface ILayout {
  children: React.ReactNode;
}
const LayoutContent = ({ children }: ILayout) => {
  const { isOpenMenu } = useContext(LayoutContext);

  const { isTablet, isMobile } = useWindowResize();

  const refHeader = useRef<HTMLDivElement | null>(null);

  const [heightHeader, setHeightHeader] = useState(0);

  useLayoutEffect(() => {
    if (refHeader.current) {
      setHeightHeader(refHeader.current.clientHeight);
    }
  }, [isOpenMenu]);

  const classWrapperNav = useCallback(() => {
    if (isMobile) {
      return isOpenMenu ? "absolute top-0 left-0 bottom-0 w-[260px] px-4 py-7 z-10" : "hidden";
    }

    return isOpenMenu ? "w-[260px] px-4 py-7" : "w-[68px] px-[10px] py-[17px]";
  }, [isMobile, isOpenMenu]);

  return (
    <>
      <div className="site-wrapper flex flex-col h-screen">
        <div className="fixed inset-x-0 top-0 z-20" ref={refHeader}>
          <Header />
        </div>
        <div className="flex-1 flex relative">
          <div
            style={{ marginTop: `${heightHeader}px` }}
            className={`fixed left-0 h-screen z-[11]`}
          >
            <Nav classWrapperNav={() => classWrapperNav()} />
          </div>
          <div className={classWrapperNav()} />
          <div
            style={{ marginTop: `${heightHeader}px` }}
            className={`flex-1 overflow-hidden ${isTablet ? "p-5" : "py-7 px-12"}`}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

const Layout = ({ children }: ILayout) => {
  useUpdateKCToken();
  return (
    <Context>
      <LayoutContent>{children}</LayoutContent>
    </Context>
  );
};

export default Layout;
