import { LayoutContext } from "components/layout/context";
import { useCallback, useContext } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ListRoutes } from "routes";
import { useWindowResize } from "utils/useWindowResize";
import { ReactComponent as List } from "../../assets/icons/List.svg";
import { ReactComponent as SignOut } from "../../assets/icons/SignOut.svg";
import { ReactComponent as X } from "../../assets/icons/X.svg";
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import keycloak from "../../keycloak";

const Header = () => {
  const { data } = useQuery("fetchUserInfo", {
    queryFn: () => keycloak.loadUserInfo(),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
  });
  const { isMobile } = useWindowResize();
  const { isOpenMenu, setIsOpenMenu } = useContext(LayoutContext);

  const renderBtnToggleNav = useCallback(
    (callback: () => void) => {
      if (isMobile) {
        return (
          <>
            {isOpenMenu ? (
              <X onClick={callback} className="cursor-pointer" />
            ) : (
              <List onClick={callback} className="cursor-pointer" />
            )}
          </>
        );
      }

      return <List onClick={callback} className="cursor-pointer" />;
    },
    [isMobile, isOpenMenu]
  );

  const user: any = data;

  const logout = () => {
    const logoutUri = `${keycloak.authServerUrl}realms/${keycloak.realm}/protocol/openid-connect/logout`;
    window.location.href = logoutUri;
  };

  return (
    <div className="flex items-center justify-between py-2.5 px-4 bg-white shadow-[0px_1px_2px_rgba(45,54,67,0.05)]">
      <div className={`flex items-center ${isMobile ? "gap-2.5" : "gap-12"}`}>
        {renderBtnToggleNav(() => setIsOpenMenu(!isOpenMenu))}
        <Link to={ListRoutes.LIST_USERS}>
          <Logo />
        </Link>
      </div>
      <div className={`flex items-center ${isMobile ? "gap-2.5" : "gap-6"}`}>
        {/* {!isMobile && !isTablet && (
          <>
            <div className="flex items-center gap-x-2.5">
              <HotlineIcon />
              <span>
                <span className="text-[#8897AE] text-sm">C&amp;B - Hotline</span>{" "}
                <a className="text-[#1C222B] text-sm" href="tel:0352526862">
                  0352526862
                </a>
              </span>
            </div>
            <div className="flex items-center gap-x-2.5">
              <GapoIcon />
              <span>
                <span className="text-[#8897AE] text-sm">Nhóm hỗ trợ</span>
                <a
                  className="text-[#1C222B] text-sm"
                  href="https://www.gapowork.vn/group/unicorn"
                  target="_blank"
                  rel="noreferrer"
                >
                  GAPO
                </a>
              </span>
            </div>
          </>
        )} */}

        <button
          className="flex items-center gap-2.5 btn py-1.5 px-3 border border-border-color hover:border-background-button-sidebar-active"
          onClick={logout}
        >
          <SignOut />
          {!isMobile && "Đăng xuất"}
        </button>
        <strong>{user?.name}</strong>
      </div>
    </div>
  );
};

export default Header;
