import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import ListUsers from "./pages/ListUsersPage";
import { ListRoutes } from "./routes";
import UserCreate from "./modules/User/components/UserCreate";
import { ApiConst } from "libs/axios/const";
import UserUpdate from "modules/User/components/UserUpdate";
import Loading from "components/Loading";

function eventLogger(eventType: string, error?: any) {
  if (eventType === "onInitError") {
    keycloak.login({
      redirectUri: `${ApiConst.redirectUrl}`,
    });
  }
}

function tokenLogger(tokenInfo: any) {
  const { token, refreshToken } = tokenInfo;
  if (!token) {
    return keycloak.login({
      redirectUri: `${ApiConst.redirectUrl}`,
    });
  }

  localStorage.setItem("kcToken", token);
  localStorage.setItem("kcRefreshToken", refreshToken);
}

function App() {
  const token = localStorage.getItem("kcToken");
  const refreshToken = localStorage.getItem("kcRefreshToken");
  return (
    <div>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          onLoad: "check-sso",
          checkLoginIframe: false,
          redirectUri: `${ApiConst.redirectUrl}`,
          token: token,
          refreshToken,
        }}
        onEvent={eventLogger}
        onTokens={tokenLogger}
        LoadingComponent={<Loading />}
      >
        <BrowserRouter basename="/">
          <Layout>
            <Routes>
              <Route
                path={`/oauth2/callback`}
                element={<Navigate to={`${ListRoutes.LIST_USERS}`} replace={true} />}
              />
              <Route path={`${ListRoutes.CREATE_USERS}`} element={<UserCreate />} />
              <Route path={`${ListRoutes.UPDATE_USERS}/:userId`} element={<UserUpdate />} />
              <Route path={`${ListRoutes.LIST_USERS}`} element={<ListUsers />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
