import Keycloak from "keycloak-js";
import { ApiConst } from "libs/axios/const";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: ApiConst.keyCloakUrl,
  realm: ApiConst.realmName || "",
  clientId: "account-management",
});

export default keycloak;
