import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { User } from "modules/User/domains/user.entity";
import { useEffect } from "react";
import { ObjectSchema } from "yup";

type PassedInProps = {
  resetOnSubmit: boolean;
  initialValues: any;
  onFinish: (user: User) => void;
  [key: string]: any;
};

const withUserForm = (schema: ObjectSchema<any>) => (WrappedComponent: any) => {
  const EnhancedComponent = ({
    resetOnSubmit,
    initialValues,
    onFinish,
    ...props
  }: PassedInProps) => {
    const [form] = useForm();

    const setFormDefault = async () => {
      await form.resetFields();
      const cloneAttributes = JSON.parse(JSON.stringify(initialValues?.attributes || ""));
      delete cloneAttributes?.gender;
      const formInitialValues = {
        ...initialValues,
        attributes: cloneAttributes,
      };
      await form.setFieldsValue(formInitialValues);
    };

    useEffect(() => {
      if (initialValues) {
        setFormDefault();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    const onSubmit = async () => {
      try {
        const user: User = form.getFieldsValue();
        const userId = form.getFieldValue("id");
        if (userId) user.id = userId;
        const res = await onFinish(user);
        return res;
      } catch (error) {
        return false;
      }
    };

    return (
      <Form form={form} onFinish={onFinish}>
        <WrappedComponent
          {...props}
          form={form}
          onSubmit={onSubmit}
          initialValues={initialValues}
        />
      </Form>
    );
  };

  return EnhancedComponent;
};

export default withUserForm;
