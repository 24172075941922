import { Rule } from "antd/es/form";
import { Form } from "antd";
import "./styles.css";

export type FormInputProps = {
  name: string | string[];
  label?: React.ReactNode;
  rules?: Rule[];
  [key: string]: any;
};

const withFormItem = (WrapperComponent: any) => {
  const EnhandcedComponent = ({ name, label, rules, ...props }: FormInputProps) => {
    return (
      <Form.Item name={name} label={label} rules={rules}>
        <WrapperComponent {...props} />
      </Form.Item>
    );
  };

  return EnhandcedComponent;
};

export default withFormItem;
