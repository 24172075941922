import React, { FC } from "react";
import { Form, Switch } from "antd";
import { NamePath } from "antd/es/form/interface";
import "./styles.scss";
import { SwitchChangeEventHandler } from "antd/es/switch";

interface CustomSwitchProps {
  name?: NamePath | undefined;
  label?: string;
  onChange?: SwitchChangeEventHandler;
  [key: string]: any;
}

const CustomFormSwitch: FC<CustomSwitchProps> = ({ name, label, onChange, ...props }) => {
  return (
    <div className="switch-item">
      <Form.Item valuePropName="checked" label={label} name={name}>
        <Switch {...props} onChange={onChange} />
      </Form.Item>
    </div>
  );
};

export default CustomFormSwitch;
