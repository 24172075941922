import { useCallback, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import departmentService from "../domains/departments/department.service";
export const useQueryDepartments = () => {
  const [pagination, setPagination] = useState(1);
  const { initialized } = useKeycloak();
  const { isLoading, error, data } = useQuery("departments", {
    queryFn: () => departmentService.queryDepartments(),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleChangePagination = useCallback((current: number, size: number) => {
    setPagination(current);
  }, []);

  return { isLoading, error, data, pagination, handleChangePagination };
};
