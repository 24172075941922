import { object, string, array, boolean, InferType } from "yup";
import { BaseRequest } from "libs/axios/types/base-request";

export interface ResetPassworDto {
  temporary: boolean;
  type: string;
  value: string;
}

export const UserSchema = object({
  id: string(),
  username: string(),
  firstName: string(),
  lastName: string(),
  email: string(),
  groups: array(string()),
  clientRoles: array(string()),
  enabled: boolean(),
  emailVerified: boolean(),
  attributes: object({
    // address
    countryName: array(string()),
    countryId: array(string()),
    districtName: array(string()),
    districtId: array(string()),
    cityName: array(string()),
    cityId: array(string()),
    wardName: array(string()),
    wardId: array(string()),
    address: array(string()),
    // jobs
    departmentName: array(string()),
    departmentId: array(string()),
    positionTitle: array(string()),
    positionId: array(string()),
    locationSelection: array(string()), // ALL / SPEC
    // legacy
    legacyId: array(string()),
    legacyUserId: array(string()),
    // other
    phoneNumber: array(string()),
    phoneNumberVerified: array(boolean()),
    workingStatus: array(string()),
    birthday: array(string()),
    gender: array(string()),
    code: array(string()),
    nhanhReferenceId: array(string()),
    // create
    createdBy: array(string()),
    createdName: array(string()),
    createdDate: array(string()),
    updatedBy: array(string()),
    updatedName: array(string()),
    updatedDate: array(string()),
  }),
});

export interface User extends BaseRequest, InferType<typeof UserSchema> {}
