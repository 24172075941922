import { DatePicker, Space } from "antd";
import { isUndefinedOrNull } from "helpers";
import dayjs from "dayjs";
import { CSSProperties } from "react";
import { DATE_FORMAT } from "consts";
import { CalendarOutlined } from "@ant-design/icons";

type CustomDatePickerProps = {
  value?: string;
  onChange?: (value: string | undefined) => void;
  style?: CSSProperties;
  placeholder?: string;
  className?: string;
  disableDate?: (date: dayjs.Dayjs) => boolean;
  format?: string;
  showTime?: boolean;
  defaultValue?: dayjs.Dayjs | undefined;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props: CustomDatePickerProps) => {
  const { value, onChange, placeholder, style, ...rest } = props;
  return (
    <DatePicker
      {...rest}
      rootClassName="custom-date-picker"
      defaultValue={props.defaultValue}
      value={!isUndefinedOrNull(value) ? dayjs(value) : undefined}
      placeholder={placeholder}
      onChange={(v, dateString) => {
        onChange && onChange(v?.format());
      }}
      disabledDate={props.disableDate}
      className={props.className}
      format={props.format || DATE_FORMAT.DDMMYYY}
      showTime={props.showTime}
      suffixIcon={
        <Space>
          <div>|</div>
          <CalendarOutlined />
        </Space>
      }
      {...rest}
    />
  );
};

export default CustomDatePicker;
