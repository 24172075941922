import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useRef } from 'react';

function useUpdateKCToken() {
  const { keycloak } = useKeycloak();
  const tokenInterval = useRef<ReturnType<typeof setInterval>>();
  const TOKEN_TIMEOUT_SECONDS = useRef<number>(60); //SỐ GIÂY TRƯỚC KHI TOKEN HẾT HẠN

  useEffect(() => {
    tokenInterval.current = setInterval(() => {
      if (keycloak.isTokenExpired(TOKEN_TIMEOUT_SECONDS.current)) {
        keycloak.updateToken(TOKEN_TIMEOUT_SECONDS.current).then(function (refreshed) {
          if (refreshed) {
            console.log('Token was successfully refreshed');
          }
          else {
            console.log('Token is still valid');
          }
        }).catch(function () {
          console.log('Failed to refresh the token, or the session has expired');
        });
      }
    }, 1000 * TOKEN_TIMEOUT_SECONDS.current);

    return () => {
      clearTimeout(tokenInterval.current);
    }
  }, [keycloak])

}

export default useUpdateKCToken;
