import { User } from "../domains/user.entity";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import userService from "../domains/user.service";
import { showError, showSuccess } from "helpers/toast";
import { useQueryStores } from "modules/Core/hooks/useQueryStore";
import moment from "moment";

export const useCreateUser = (callback: () => void) => {
  const { data: stores } = useQueryStores();
  const queryClient = useQueryClient();
  const userInfo: any = queryClient.getQueryData(["fetchUserInfo"]);

  const createUser = useCallback(
    async (user: any) => {
      if (!user) return;
      // const userKeycloak =
      const {
        stores: storeIds,
        username,
        email,
        firstName,
        lastName,
        attributes,
        groups,
        emailVerified,
        gender,
      } = user;

      const now = moment();
      const formattedDate = now.format("YYYY-MM-DD HH:mm:ss");

      const newAttributes = {
        ...attributes,
      };
      const { locationSelectionData } = user?.attributes;

      const fieldDelete = [
        "departmentData",
        "positionData",
        "wardData",
        "districtData",
        "countryData",
        "locationSelectionData",
      ];
      fieldDelete.forEach((field) => {
        delete newAttributes[field];
      });

      let newUser: any = {
        username,
        email: email?.trim(),
        firstName,
        lastName,
        attributes: {
          ...newAttributes,
          gender: [gender],
          locationSelection: locationSelectionData ? "ALL" : "SPEC",
          createdBy: userInfo?.preferred_username,
          createdName: userInfo?.name,
          createdDate: formattedDate,
        },
        groups,
        emailVerified: emailVerified,
        enabled: true,
      };

      if (!newUser.email) {
        newUser.email = `id+${username}@yody.vn`;
        // If email isn't readable format then auto verify email
        newUser.emailVerified = true;
      }

      const { attributes: attr } = newUser;
      const newAttr: any = {};
      Object.keys(attr).forEach((key: any) => {
        const keyName = key;
        if (!Array.isArray(attr[keyName])) {
          newAttr[keyName] = attr?.[keyName] ? [attr?.[keyName]] : [""];
        } else {
          newAttr[keyName] = attr?.[keyName];
        }
      });

      newUser = {
        ...newUser,
        attributes: newAttr,
      };

      const res = await userService.createUser(newUser, storeIds, stores);
      return res;
    },
    [stores, userInfo?.name, userInfo?.preferred_username]
  );

  const mapErr = (err?: string) => {
    if (err?.includes("same username")) {
      return "Tài khoản đã tồn tại, vui lòng kiểm tra lại.";
    } else if (err?.includes("same email")) {
      return "Email đã tồn tại, vui lòng kiểm tra lại.";
    } else return "Tạo tài khoản thất bại, vui lòng kiểm tra lại email hoặc số điện thoại.";
  };

  const mutation = useMutation(
    "createUser",
    (user: User) => {
      return createUser(user);
    },
    {
      onSuccess: () => {
        showSuccess("Tạo tài khoàn nhân viên thành công.");
        callback();
      },
      onError: (error: any) => {
        showError(
          mapErr(error?.response?.data?.errorMessage) ||
            "Tạo tài khoản thất bại, vui lòng kiểm tra lại email hoặc số điện thoại."
        );
      },
    }
  );

  return { mutation };
};
