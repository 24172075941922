import { Button } from "antd";
import { User } from "../../../User/domains/user.entity";
import { useHandleConfirm } from "../../hooks/useHandleConfirm";
import { useDeleteUser } from "../../hooks/useDeleteUser";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { useResetPassword } from "modules/User/hooks/useResetPassword";

interface IGroupActionUser {
  user: User;
  handleQueryUser: () => void;
  listUser?: User[];
  callback: () => void;
  onSuccess: () => void;
}
export const GroupActionUser = ({
  user,
  handleQueryUser,
  listUser,
  callback,
  onSuccess,
}: IGroupActionUser) => {
  const { deleting, onDeleteUser } = useDeleteUser({ selectUser: user, callback: onSuccess });
  const { updating, onUpdateUser } = useUpdateUser({
    selectUser: user?.id ? [user?.id] : undefined,
    newValues: [
      {
        id: user.id,
        enabled: !user?.enabled,
      },
    ],
    handleQueryUser,
    listUser,
  });
  const { showConfirmDeleteAccount, showConfirmLockAccount, showConfirmUnLockAccount } =
    useHandleConfirm({ deleting, onDeleteUser, updating, onUpdateUser });

  return (
    <>
      <div className="flex flex-col gap-2">
        <Button
          type="link"
          className="px-2.5 py-1 flex items-start h-auto hover:bg-background-button-hover rounded"
          onClick={() => {
            callback();
            if (user?.enabled) {
              showConfirmLockAccount();
            } else {
              showConfirmUnLockAccount();
            }
          }}
        >
          <span className="block">{user?.enabled ? "Khóa tài khoản" : "Mở khóa tài khoản"}</span>
        </Button>
        {/* <Button
          type="link"
          className="px-2.5 py-1 flex items-start h-auto hover:bg-background-button-hover rounded"
          onClick={() => {
            showConfirmDeleteAccount();
          }}
        >
          <span className="text-danger block">Xóa tài khoản</span>
        </Button> */}
      </div>
    </>
  );
};
