/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Key, useCallback, useMemo, useRef, useState } from "react";
import { ReactComponent as UploadSimple } from "../../assets/icons/UploadSimple.svg";
import { ReactComponent as DownloadSimple } from "../../assets/icons/DownloadSimple.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/icons/MagnifyingGlass.svg";
import { ReactComponent as LockKey } from "../../assets/icons/LockKey.svg";
import { ReactComponent as UnLock } from "../../assets/icons/UnLock.svg";
import Pagination from "antd/lib/pagination";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { limit, useFetchUserList } from "./hooks/useFetchUserList";
import Loading from "../../components/Loading";
import { Button, Divider, Input, Radio, Space, Table, Upload } from "antd";
import { useCountUserList } from "./hooks/useCountUserList";
import { useSelectedUsers } from "./hooks/useSelectedUsers";
import { useHandleConfirm } from "./hooks/useHandleConfirm";
import { ListRoutes } from "routes";
import { useWindowResize } from "utils/useWindowResize";
import { useConfigTable } from "modules/User/hooks/useConfigTable";
import { useConfirmModal } from "hooks/useConfirmModal";
import { showSuccess, showWarning } from "helpers/toast";
import { useExportExcel } from "utils/useExportExcel";
import * as XLSX from "xlsx";
import { useExportUser } from "modules/User/hooks/useExportUser";
import { useUpdateUser } from "./hooks/useUpdateUser";
import { useValidatePhoneField } from "utils/useValidatePhoneField";
import { useQueryCountries } from "modules/Core/hooks/useQueryCountries";
import { CountriesResponse } from "modules/Core/domains/countries/countries.entity";
import { DistrictsResponse } from "modules/Core/domains/districts/districts.entity";
import { useQueryStores } from "modules/Core/hooks/useQueryStore";
import { useQueryDepartments } from "modules/Core/hooks/useQueryDepartment";
import { useQueryPositions } from "modules/Core/hooks/useQueryPosition";
import userService from "./domains/user.service";
import { useQueryRoles } from "modules/Core/hooks/useQueryRole";
import moment from "moment-timezone";
import wardsService from "modules/Core/domains/wards/wards.service";
import { useResetPassword } from "./hooks/useResetPassword";
import { KeyOutlined } from "@ant-design/icons";
interface IImportError {
  rowNum: number;
  error: string;
}

const genderMap: any = {
  Nam: "male",
  Nữ: "female",
  Khác: "other",
};

function ListUsersPage() {
  const {
    isLoading,
    isFetching,
    data,
    keySearch,
    pagination,
    handleChangePagination,
    onChangeKeySearch,
    handleQueryUser,
    refetch,
  } = useFetchUserList();
  const { totalUsers, handleCountUser } = useCountUserList(keySearch);
  const { selectedUser, setSelectedUser, setNewValue, newValue } = useSelectedUsers();
  const [isUpdateSimple, setIsUpdateSimple] = useState(false);

  const { onUpdateUser } = useUpdateUser({
    selectUser: selectedUser || [],
    newValues: data
      ? data.map((user) => {
          return {
            id: user.id,
            enabled: isUpdateSimple ? !user?.enabled : newValue.enabled,
          };
        })
      : [],
    handleQueryUser,
    listUser: data,
  });

  const { showConfirmLockAccount, showConfirmUnLockAccount } = useHandleConfirm({
    onUpdateUser: onUpdateUser,
  });
  const { isTablet, isMobile } = useWindowResize();
  const { columns, columnsMobile, renderTotal, itemRender } = useConfigTable({
    pagination,
    totalUsers,
    limit,
    handleQueryUser,
    listUser: data,
  });

  const listUsers = useMemo(() => data, [data]);

  const classBtn = useCallback(() => {
    return `${
      isTablet ? "h-10 w-[42px] rounded" : "gap-2.5 btn px-5 py-2.5"
    } flex items-center justify-center border border-border-color hover:border-background-button-sidebar-active`;
  }, [isTablet]);

  const classInputWrapper = useCallback(() => {
    let className =
      "flex items-center border-b gap-2 py-2.5 bg-background-input-search border-title-sidebar";
    if (isMobile) {
      className += " flex-1 w-auto";
    } else if (isTablet) {
      className += " w-56";
    } else className += " w-80";
    return className;
  }, [isMobile, isTablet]);

  const [exportPageAll, setExportPageAll] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const { handleExportUser } = useExportUser({
    setIsLoadingExport,
    usersList: data,
    keySearch: keySearch.current,
  });

  const onChangeExportPageOption = (e: any) => {
    setExportPageAll(e.target.value);
  };

  const exportUserContent = useMemo(() => {
    return (
      <div className="export-customer-modal">
        <div>
          <div>
            <strong>Chọn trang</strong>
          </div>
          <div className="h-1"></div>
          <Radio.Group
            onChange={onChangeExportPageOption}
            value={exportPageAll}
            className="radio-group"
          >
            <Space className="radio-option">
              <Radio value={true} key="all-page">
                Tất cả các trang
              </Radio>
              <Radio value={false} key="current-page">
                Trang hiện tại
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
    );
  }, [exportPageAll]);

  const {
    showModal: showModalExport,
    hideModal: hideModalExport,
    ConfirmModal: ConfirmModalExport,
  } = useConfirmModal({
    onConfirm: async () => {
      await handleExportUser(exportPageAll);
      hideModalExport();
    },
    onCancel: () => {
      hideModalExport();
    },
    content: exportUserContent,
    title: "Xuất excel danh sách tài khoản",
    isLoading: isLoadingExport,
  });

  const [fileList, setFileList] = useState<Array<File>>([]);

  const onRemoveFile = (file: any) => {
    setFileList([]);
  };

  const beforeUploadFile = useCallback((file: File) => {
    const isExcelFile =
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    if (!isExcelFile) {
      showWarning("Vui lòng chọn đúng định dạng file excel .xlsx .xls");
      return Upload.LIST_IGNORE;
    } else {
      setFileList([file]);
      return false;
    }
  }, []);

  const [importErrors, setImportErrors] = useState<IImportError[]>([]);
  const importErrorsRef = useRef<IImportError[]>([]);
  const { exportExcelFile } = useExportExcel();
  const importUserContent = useMemo(() => {
    const columns = [
      {
        title: "Dòng",
        dataIndex: "rowNum",
        key: "rowNum",
      },
      {
        title: "Thông tin",
        dataIndex: "error",
        key: "error",
      },
    ];

    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space direction="horizontal" size={16}>
          <Upload
            beforeUpload={beforeUploadFile}
            onRemove={onRemoveFile}
            maxCount={1}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          >
            <Button icon={<UploadOutlined />}>Chọn file</Button>
          </Upload>
          <a
            className="underline text-blue-500"
            onClick={() => {
              const fileName = "Account_Template";
              const workSheetColumnName = [
                "Tài khoản/Mã nhân viên*",
                "Họ*",
                "Tên*",
                "Giới tính*",
                "Số điện thoại*",
                "Quốc gia*",
                "Khu vực*",
                "Phường xã*",
                "Nhóm quyền",
                "Email",
                "Ngày sinh",
                "Cửa hàng",
                "Phòng ban*",
                "Vị trí*",
              ];

              exportExcelFile(workSheetColumnName, [], fileName, fileName);
            }}
          >
            Tải file mẫu
          </a>
        </Space>
        <Divider />
        <Space direction="vertical">
          <div className="font-semibold">Trạng thái nhập excel</div>
          <Table
            dataSource={importErrors}
            columns={columns}
            scroll={{ y: "200px" }}
            locale={{ emptyText: "Không có lỗi trong quá trình nhập excel" }}
          />
        </Space>
      </Space>
    );
  }, [beforeUploadFile, exportExcelFile, importErrors]);

  const addImportInfo = useCallback((infoObj: IImportError) => {
    importErrorsRef.current = [...importErrorsRef.current, infoObj];
  }, []);

  const { normalizePhoneNumber } = useValidatePhoneField();

  const { countries, districts } = useQueryCountries();

  const { data: stores } = useQueryStores();

  const { data: departments } = useQueryDepartments();

  const { data: positions } = useQueryPositions();

  const { data: groups } = useQueryRoles();

  const [importing, setImporting] = useState<boolean>(false);
  const countryMap: { [key: string]: CountriesResponse } = useMemo(() => {
    const result: any = {};
    if (!countries) return result;
    countries.forEach((country) => {
      result[country.name] = country;
    });
    return result;
  }, [countries]);

  const districtMap: { [key: string]: DistrictsResponse } = useMemo(() => {
    const result: any = {};
    if (!districts) return result;
    districts.forEach((district) => {
      result[`${district.city_name} - ${district.name}`] = district;
    });
    return result;
  }, [districts]);

  const storeMap = useMemo(() => {
    const result: any = {};
    if (!stores) return result;
    stores.forEach((store) => {
      if (store.description) {
        result[store.description] = store;
      }
    });
    return result;
  }, [stores]);

  const departmentMap = useMemo(() => {
    const result: any = {};
    if (!departments) return result;
    const traverseDepartment = (department: any) => {
      result[department.name] = department;
      if (department.children) {
        department.children.forEach((departmentChildren: any) => {
          traverseDepartment(departmentChildren);
        });
      }
    };
    departments.forEach((department: any) => {
      traverseDepartment(department);
    });
    return result;
  }, [departments]);

  const positionMap = useMemo(() => {
    const result: any = {};
    if (!positions) return result;
    positions.forEach((position: any) => {
      if (position.name) {
        result[position.name] = position;
      }
    });
    return result;
  }, [positions]);

  const groupMap = useMemo(() => {
    const result: any = {};
    if (!groups) return result;
    const traverseGroup = (group: any) => {
      if (group.name) {
        result[group.name] = group;
      }
      if (group.subGroups) {
        group.subGroups.forEach((subGroup: any) => traverseGroup(subGroup));
      }
    };
    groups.forEach((group: any) => {
      traverseGroup(group);
    });
    return result;
  }, [groups]);

  const onFileImport = useCallback(async () => {
    importErrorsRef.current = [];
    setImportErrors([]);
    // Create a new FileReader object to read the file data.
    const reader = new FileReader();

    // Define a promise to read the file data and return a parsed workbook.
    const getWorkbook = () => {
      return new Promise((resolve, reject) => {
        reader.onload = (event: any) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          resolve(workbook);
        };
        reader.onerror = (event) => {
          reject(event);
        };
        reader.readAsArrayBuffer(fileList[0]);
      });
    };

    // Read the file data and parse the workbook.
    try {
      setImporting(true);
      const workbook: any = await getWorkbook();
      const sheetNames = workbook.SheetNames;
      const worksheet = workbook.Sheets[sheetNames[0]];
      const data: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      let index = 0;
      for (const row of data) {
        index++;
        if (index === 1) continue;
        if (!row || row.length === 0) continue;
        let [
          username,
          lastName,
          firstName,
          gender,
          phoneNumber,
          country,
          district,
          ward,
          groupsImport,
          email,
          birthday,
          storesImport,
          department,
          position,
        ] = row;

        let groups: string[] = groupsImport.split(",");
        groups = groups.map((group) => group.trim());

        let stores: string[] = storesImport.split(",");
        stores = stores.map((store) => store.trim());
        if (!username || username === "") {
          addImportInfo({
            rowNum: index,
            error: "Thiếu tên tài khoản",
          });
          continue;
        }
        if (!firstName || firstName === "") {
          addImportInfo({
            rowNum: index,
            error: "Thiếu họ",
          });
          continue;
        }
        if (!lastName || lastName === "") {
          addImportInfo({
            rowNum: index,
            error: "Thiếu tên",
          });
          continue;
        }
        if (!gender) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu giới tinh",
          });
          continue;
        } else {
          if (!genderMap[gender]) {
            addImportInfo({
              rowNum: index,
              error: "Vui lòng nhập giới tính là Nam, Nữ hoặc Khác",
            });

            continue;
          }
        }

        if (!phoneNumber) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu số điện thoại",
          });
          continue;
        } else {
          const PHONE_PATTERN = /((\+84|0|84){1}(9|8|7|5|3)){1}([0-9]{8})/g;
          if (PHONE_PATTERN.test(phoneNumber) === false) {
            addImportInfo({
              rowNum: index,
              error: "Số điện thoại sai định dạng",
            });
            continue;
          }
        }

        if (!country) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu thông tin quốc gia",
          });
          continue;
        } else {
          if (!countryMap[country]) {
            addImportInfo({
              rowNum: index,
              error: "Thông tin quốc gia nằm ngoài danh sách cho phép",
            });
            continue;
          }
        }

        if (!district) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu thông tin khu vực",
          });
          continue;
        } else {
          if (!districtMap[district]) {
            addImportInfo({
              rowNum: index,
              error: "Thông tin khu vực nằm ngoài danh sách cho phép",
            });
            continue;
          }
        }

        if (!ward) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu thông tin phường xã",
          });
          continue;
        }

        let invalidGroup = false;
        for (let group of groups) {
          if (!group) {
            invalidGroup = true;
            addImportInfo({
              rowNum: index,
              error: "Thiếu thông tin nhóm quyền",
            });
            break;
          } else {
            if (!groupMap[group]) {
              invalidGroup = true;
              addImportInfo({
                rowNum: index,
                error: "Thông tin nhóm quyền nằm ngoài danh sách cho phép",
              });
              break;
            }
          }
        }
        if (invalidGroup) continue;

        let invalidStore = false;
        for (let store of stores) {
          if (!store) {
            invalidStore = true;
            addImportInfo({
              rowNum: index,
              error: "Thiếu thông tin cửa hàng",
            });
            break;
          } else {
            if (!storeMap[store]) {
              invalidStore = true;
              addImportInfo({
                rowNum: index,
                error: "Thông tin cửa hàng nằm ngoài danh sách cho phép",
              });
              break;
            }
          }
        }
        if (invalidStore) continue;

        if (!department) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu thông tin phòng ban",
          });
          continue;
        } else {
          if (!departmentMap[department]) {
            addImportInfo({
              rowNum: index,
              error: "Thông tin phòng ban nằm ngoài danh sách cho phép",
            });
            continue;
          }
        }

        if (!position) {
          addImportInfo({
            rowNum: index,
            error: "Thiếu thông tin vị trí",
          });
          continue;
        } else {
          if (!positionMap[position]) {
            addImportInfo({
              rowNum: index,
              error: "Thông tin vị trí nằm ngoài danh sách cho phép",
            });
            continue;
          }
        }

        /*
        username,
        firtName,
        lastName,
        gender,
        phoneNumber,
        country,
        district,
        ward,
        groupImport,
        email,
        birthday,
        store,
        department,
        position,
        */

        const wardMap: any = {};
        const wards = await wardsService.queryWards(Number(districtMap[district]?.id));
        wards?.forEach((wardInfo) => {
          wardMap[ward] = wardInfo;
        });

        const groupPaths = groups.map((group) => groupMap[group].path);

        const createUserDto = {
          username: username.toLowerCase(),
          lastName: lastName,
          firstName: firstName,
          enabled: true,
          email: email ? email : `id+${username}@yody.vn`,
          emailVerified: true,
          groups: groupPaths,
          attributes: {
            phoneNumber: normalizePhoneNumber(phoneNumber),
            birthday: moment(birthday, "DD/MM/YYYY").tz("Asia/Ho_Chi_Minh").toISOString(),
            gender: [genderMap[gender]],
            countryName: countryMap[country].name,
            countryId: countryMap[country].id,
            cityName: districtMap[district]?.city_name || "",
            cityId: districtMap[district]?.city_id?.toString() || "",
            districtName: districtMap[district]?.name || "",
            districtId: districtMap[district]?.id?.toString() || "",
            wardName: wardMap[ward]?.name || "",
            wardId: wardMap[ward]?.id?.toString() || "",
            departmentName: departmentMap[department].name,
            departmentId: departmentMap[department].id,
            positionTitle: positionMap[position].name,
            positionId: positionMap[position].id,
          },
        };

        const storeIds = stores.map((store) => storeMap[store]?.id);
        const storesFull = stores.map((store) => storeMap[store]);
        try {
          await userService.createUser(createUserDto, storeIds, storesFull);
        } catch (error) {
          addImportInfo({
            rowNum: index,
            error: "Có lỗi xảy ra khi tạo tài khoản, liên hệ với IT để được hỗ trợ",
          });
          continue;
        }
      }

      showSuccess("Nhập excel danh sách tài khoản hoàn tất");
      setImportErrors(importErrorsRef.current);
      setImporting(false);
      // Do something with the workbook...
    } catch (error: any) {
      console.error("Failed to read file", error.message);
    }
  }, [
    fileList,
    groupMap,
    normalizePhoneNumber,
    districtMap,
    positionMap,
    addImportInfo,
    countryMap,
    storeMap,
    departmentMap,
  ]);

  const {
    showModal: showModalImport,
    hideModal: hideModalImport,
    ConfirmModal: ConfirmModalImport,
  } = useConfirmModal({
    onConfirm: () => {
      onFileImport();
    },
    onCancel: () => {
      setImportErrors([]);
      importErrorsRef.current = [];
      hideModalImport();
    },
    content: importUserContent,
    title: "Nhập excel danh sách tài khoản",
    isLoading: importing,
  });
  const listSelectedUser = useMemo(() => {
    return listUsers?.filter((user) => {
      if (!user?.id) return false;
      return selectedUser.includes(user?.id);
    });
  }, [selectedUser, listUsers]);
  const { showModalResetPassword, ResetPasswordModal } = useResetPassword({
    selectedUser: listSelectedUser,
  });
  const navigate = useNavigate();

  return (
    <>
      {ResetPasswordModal}
      {ConfirmModalExport}
      {ConfirmModalImport}
      <div className={`flex items-center justify-between ${isTablet ? "mb-3" : "mb-8"}`}>
        <h2 className={`${isTablet ? "text-[18px]" : "text-2xl"} text-title-page font-semibold`}>
          Danh sách nhân viên
        </h2>
        <Space size={2}>
          {!isTablet && (
            <>
              <Button onClick={() => showModalImport()} className={classBtn()} type="default">
                <UploadSimple />
                Nhập file excel
              </Button>
              <Button onClick={() => showModalExport()} className={classBtn()} type="default">
                <DownloadSimple />
                Xuất file excel
              </Button>
              <Button type="primary" onClick={() => navigate(ListRoutes.CREATE_USERS)}>
                <Plus className={`${isTablet && "w-3.5 h-3.5 block"}`} />
                {!isTablet && "Tạo tài khoản"}
              </Button>
            </>
          )}
        </Space>
      </div>
      <div className={`flex items-center justify-between ${isTablet ? "mb-5" : "mb-6"}`}>
        <div
          className={`${
            isTablet && "flex-1"
          } search-wrapper flex items-center justify-between gap-2`}
        >
          <div className={classInputWrapper()}>
            <MagnifyingGlass
              className="ml-3"
              onClick={() => {
                handleQueryUser();
                handleCountUser();
              }}
            />
            <Input
              onChange={(e) => {
                onChangeKeySearch(e);
              }}
              onPressEnter={() => {
                handleQueryUser();
                handleCountUser();
              }}
              placeholder="Tìm kiếm nhân viên"
              className="input-search"
            />
          </div>
          <div className="flex items-center justify-between gap-2">
            <Button
              onClick={() => {
                showConfirmUnLockAccount();
                setNewValue({ enabled: true });
                setIsUpdateSimple(false);
              }}
              disabled={selectedUser.length <= 0}
              type="default"
              className={classBtn()}
            >
              {isTablet ? <UnLock /> : "Mở khóa tài khoản"}
            </Button>
            <Button
              onClick={() => {
                showConfirmLockAccount();
                setNewValue({ enabled: false });
                setIsUpdateSimple(false);
              }}
              disabled={selectedUser.length <= 0}
              type="default"
              className={classBtn()}
            >
              <LockKey />
              {!isTablet && "Khóa tài khoản"}
            </Button>
            <Button
              onClick={() => {
                showModalResetPassword();
              }}
              disabled={selectedUser.length <= 0}
              type="default"
              className={classBtn()}
            >
              {isTablet ? <KeyOutlined /> : "Đặt lại mật khẩu"}
            </Button>
          </div>
        </div>
        {!isTablet && renderTotal()}
      </div>
      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <Table
          className="table-config-tag mb-7"
          bordered={!isMobile}
          rowSelection={{
            type: "checkbox",
            onChange: (newSelectedRowKeys: Key[]) =>
              setSelectedUser(newSelectedRowKeys as string[]),
            columnWidth: isMobile ? 20 : 48,
            selectedRowKeys: selectedUser,
          }}
          rowClassName="py-3"
          columns={
            isMobile
              ? columnsMobile(() => setIsUpdateSimple(true), refetch)
              : columns(() => setIsUpdateSimple(true), refetch)
          }
          scroll={{ x: "calc(150%)" }}
          dataSource={listUsers ?? []}
          pagination={false}
          rowKey="id"
          locale={{ emptyText: "Không tìm thấy tài khoản phù hợp" }}
        />
      )}
      <div className={`${isTablet ? "justify-center" : "justify-between"} flex items-center`}>
        {!isTablet && renderTotal()}
        <Pagination
          className="border-0"
          itemRender={itemRender}
          showSizeChanger={false}
          onChange={handleChangePagination}
          current={pagination + 1}
          showLessItems
          pageSize={limit}
          total={totalUsers}
        />
      </div>
    </>
  );
}

export default ListUsersPage;
