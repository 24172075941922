import { useCreateUser } from "modules/User/hooks/useCreateUser";
import { User, UserSchema } from "../../domains/user.entity";
import withUserForm from "../hocs/withUserForm";
import { UserForm } from "../UserForm";
import { useState } from "react";
import { defaultCountryId, defaultCountryName } from "modules/Core/hooks/useQueryCountries";

// Wrap the form component with the withFormValidation HOC
const UserCreateForm = withUserForm(UserSchema)(UserForm);
const initialValues = {
  emailVerified: true,
  attributes: {
    countryData: defaultCountryName,
    countryName: defaultCountryName,
    countryId: defaultCountryId,
  },
};
// Define the page component
export default function UserCreate() {
  const [defaultValue, setDefaultValue] = useState(initialValues);
  const { mutation } = useCreateUser(() => setDefaultValue({ ...initialValues }));

  const handleSubmit = (user: User) => {
    mutation.mutate(user);
  };

  return (
    <div>
      <div className="font-inter font-semibold text-2xl leading-9 tracking-tight text-title-page mb-7">
        Tạo tài khoản người dùng
      </div>
      <UserCreateForm resetOnSubmit={true} onFinish={handleSubmit} initialValues={defaultValue} />
    </div>
  );
}
