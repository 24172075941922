import { useCallback, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import roleService from "../domains/role/role.service";
export const useQueryRoles = () => {
  const [pagination, setPagination] = useState(1);
  const { initialized } = useKeycloak();
  const { isLoading, error, data } = useQuery("roles", {
    queryFn: () => roleService.queryRoles(),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleChangePagination = useCallback((current: number, size: number) => {
    setPagination(current);
  }, []);

  return { isLoading, error, data, pagination, handleChangePagination };
};
