import { showError } from "helpers/toast";
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import keycloak from "keycloak";
import { ApiConst } from "./const";

export interface AdminResponse<D = {}> {
  data?: D;
  status?: number;
  message?: string;
}

export const REQUEST_TIMEOUT = 30000;
export const defaultHeaders = {
  "Content-Type": "application/json",
};

const onRequest = async (req: any) => {
  const kcToken = localStorage.getItem("kcToken");
  return {
    ...req,
    headers: {
      Authorization: kcToken ? `Bearer ${kcToken}` : undefined,
    },
  };
};

const onRequestError = async (error: any) => {
  throw error;
};

const onResponseError = async (error: any) => {
  if (error.response.status === 401) {
    keycloak.login();
  }
  throw error;
};

const onResponse = async (res: any): Promise<any> => {
  if (res.code === "401") return showError("Lỗi xác thực");
  if (res.code === "403") return showError("Bạn không có quyền thực hiện chức năng này");
  const responseLocation = res.headers.location;
  return {
    data: res.data,
    responseLocation,
  };
};

export const createRequest = (
  headers: Partial<AxiosRequestHeaders>,
  configs?: AxiosRequestConfig<any>
) => {
  const instance = axios.create({
    baseURL: `${ApiConst.keyCloakUrl}admin/realms/${ApiConst.realmName}/`,
    headers,
    timeout: REQUEST_TIMEOUT,
    ...configs,
  });

  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, (error) => onResponseError(error));

  return instance;
};

const axiosKeycloak = createRequest(defaultHeaders);

export default axiosKeycloak;
