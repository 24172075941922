import React from "react";
import { DepartmentResponse } from "modules/Core/domains/departments/department.entity";
import { TreeSelect } from "antd";

const TreeDepartment = (item: DepartmentResponse) => {
  return (
    <TreeSelect.TreeNode value={item.id} title={item.name}>
      {item.children?.length > 0 && (
        <React.Fragment>
          {item.children?.map((item: any, index: any) => (
            <React.Fragment key={index}>{TreeDepartment(item)}</React.Fragment>
          ))}
        </React.Fragment>
      )}
    </TreeSelect.TreeNode>
  );
};

export default TreeDepartment;
