import { useGetUserById } from "modules/User/hooks/useGetUserById";
import { useUpdateFormUser } from "modules/User/hooks/useUpdateFormUser";
import { useParams } from "react-router-dom";
import { User, UserSchema } from "../../domains/user.entity";
import withUserForm from "../hocs/withUserForm";
import { UserForm } from "../UserForm";
import { useEffect, useMemo, useState } from "react";

// Wrap the form component with the withFormValidation HOC
const UserUpdateForm = withUserForm(UserSchema)(UserForm);

// Define the page component
export default function UserUpdate() {
  const { userId } = useParams();
  const data = useGetUserById({ userId });
  const [userInfo, setUserInfo] = useState(data);
  const { mutation } = useUpdateFormUser();

  useEffect(() => {
    if (data) setUserInfo(data);
  }, [data]);

  const handleSubmit = async (user: User) => {
    mutation.mutate({
      user,
      oldUser: userInfo,
      callback: (user) => {
        const { attributes } = user;
        const newAttributes: any = {};
        Object.keys(attributes).forEach((key: any) => {
          const keyName = key;
          if (!Array.isArray(attributes[keyName])) {
            newAttributes[keyName] = attributes?.[keyName] ? [attributes?.[keyName]] : [""];
          } else {
            newAttributes[keyName] = attributes?.[keyName];
          }
        });
        const newUser = { ...user, attributes: newAttributes };
        setUserInfo(newUser);
      },
    });
  };

  const isServiceAccount = useMemo(() => {
    const username = userInfo.username;
    return username?.startsWith("service-account");
  }, [userInfo]);

  const { attributes } = userInfo;

  const department = () => {
    if (!attributes) return undefined;
    const { departmentName, departmentId } = attributes;
    if (departmentName?.[0] && departmentId?.[0]) {
      return JSON.stringify({
        name: departmentName?.[0],
        id: departmentId?.[0],
      });
    } else return undefined;
  };

  const position = () => {
    if (!attributes) return undefined;
    const { positionTitle, positionId } = attributes;
    if (positionTitle?.[0] && positionId?.[0]) {
      return JSON.stringify({
        name: positionTitle?.[0],
        id: positionId?.[0],
      });
    } else return undefined;
  };

  const country = () => {
    if (!attributes) return undefined;
    const { countryName, countryId } = attributes;
    if (countryName?.[0] && countryId?.[0]) {
      return countryName?.[0];
    } else return undefined;
  };

  const district = () => {
    if (!attributes) return undefined;
    const { cityId, cityName, districtId, districtName } = attributes;
    if (cityId?.[0] && cityName?.[0] && districtId?.[0] && districtName?.[0]) {
      return JSON.stringify({
        cityId: cityId[0],
        cityName: cityName[0],
        districtId: districtId[0],
        districtName: districtName[0],
      });
    } else return undefined;
  };

  const ward = () => {
    if (!attributes) return undefined;
    const { wardName, wardId } = attributes;
    if (wardName?.[0] && wardId?.[0]) {
      return JSON.stringify({
        name: wardName[0],
        id: wardId[0],
      });
    } else return undefined;
  };

  const locationSelection = () => {
    if (!attributes) return undefined;
    const { locationSelection } = attributes;
    if (locationSelection?.[0]) {
      return locationSelection?.[0] === "ALL" ? true : false;
    } else return false;
  };

  const defaultData = {
    ...userInfo,
    attributes: {
      ...userInfo?.attributes,
      departmentData: department(),
      // positionData: position(),
      countryData: country(),
      districtData: district(),
      wardData: ward(),
      locationSelectionData: locationSelection(),
    },
    gender: userInfo?.attributes?.gender?.[0],
  };

  return (
    <div>
      <div className="font-inter font-semibold text-2xl leading-9 tracking-tight text-title-page mb-7">
        Cập nhật tài khoản người dùng
      </div>
      <UserUpdateForm
        isUpdate
        resetOnSubmit={false}
        onFinish={handleSubmit}
        initialValues={defaultData}
        isServiceAccount={isServiceAccount}
        districtIdDefault={attributes?.districtId?.[0]}
        locationSelectionDefault={locationSelection()}
      />
    </div>
  );
}
