import { useCallback, useMemo, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import departmentService from "../domains/departments/department.service";
import storeService from "modules/Store/domains/store.service";
import { Store } from "../../Store/domains/store.entity";
import { EnumLocation } from "../domains/store/store.entity";

const findDepartment = (deptList: any, deptName?: string, deptId?: string, parent = null) => {
  if (!deptList?.length || deptList?.length <= 0) return null;
  for (let i = 0; i < deptList?.length; i++) {
    const dept = deptList[i];
    if (dept.id == deptId && dept.name == deptName) {
      return {
        ...dept,
        found_parent: parent,
      };
    }
    if (dept.children) {
      const childDept: any = findDepartment(dept.children, deptName, deptId, dept);
      if (childDept) {
        return childDept;
      }
    }
  }
  return null;
};

export const useQueryStores = () => {
  const [pagination, setPagination] = useState(1);
  const { initialized } = useKeycloak();
  const { isLoading, error, data } = useQuery("stores", {
    queryFn: () => storeService.fetchStoreList(),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: dataDepartment } = useQuery("departments", {
    queryFn: () => departmentService.queryDepartments(),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleChangePagination = useCallback((current: number) => {
    setPagination(current);
  }, []);

  const aggregateDataStore = useMemo(() => {
    const result = data?.map((store) => {
      const storeDepartmentId = store.attributes?.departmentId
        ? store.attributes?.departmentId[0]
        : "";
      const storeDepartmentName = store.attributes?.department
        ? store.attributes?.department[0]
        : "";

      let deptHierarchy = findDepartment(dataDepartment, storeDepartmentName, storeDepartmentId);

      const storeConverted: any = {
        id: store.id,
        code: store.name,
        name: store.description,
        department_id: storeDepartmentId,
        // departmnent: storeDepartmentName,
      };
      for (let i = 2; i <= 6; i++) {
        const parentKey = `department_h${i}`;
        const idKey = `department_h${i}_id`;

        if (deptHierarchy?.parent) {
          storeConverted[parentKey] = deptHierarchy.parent;
          storeConverted[idKey] = deptHierarchy.parent_id;
          deptHierarchy = deptHierarchy.found_parent;
        } else {
          storeConverted[parentKey] = null;
          storeConverted[idKey] = null;
        }
      }

      return storeConverted;
    });

    return result;
  }, [data, dataDepartment]);

  return { isLoading, error, data, pagination, handleChangePagination, aggregateDataStore };
};

export const useStoresWithType = () => {
  const [pagination, setPagination] = useState(1);
  const { initialized } = useKeycloak();
  const { isLoading, error, data } = useQuery("stores", {
    queryFn: () => storeService.fetchStoreList(),
    retry: 0,
    enabled: initialized,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handleChangePagination = useCallback((current: number) => {
    setPagination(current);
  }, []);

  const aggregateDataStore = data?.map((store) => {
    const storeType = getStoreType(store)

    const storeConverted: any = {
      id: store.id,
      code: store.name,
      name: store.description,
      storeType: storeType.storeType,
      storeTypeName: storeType.storeTypeName
    };
    return storeConverted;
  });
  return { isLoading, error, data, pagination, handleChangePagination, aggregateDataStore: aggregateDataStore };
};

function getStoreType(store: Store): { storeType: string, storeTypeName: string } {
  const types = store.attributes?.type;
  if (!types || types.every(isBlank)) {
    return { storeType: "unknown", storeTypeName: EnumLocation.unknown };
  }
  for (const type of types) {
    if (!isBlank(type)) {
      const matchedEnum = findEnumValueByString(EnumLocation, type as string);
      if (matchedEnum) {
        return { storeType: type as string, storeTypeName: matchedEnum };
      }
    }
  }
  return { storeType: "unknown", storeTypeName: EnumLocation.unknown };
}

function isBlank(value: string | undefined): boolean {
  return !value || value.trim() === "";
}

function findEnumValueByString(enumObj: any, value: string): string | undefined {
  const key = (Object.keys(enumObj) as string[]).find(
    enumKey => enumKey.toLowerCase() === value.toLowerCase()
  );
  return key ? enumObj[key] : undefined;
}