import { User } from "./../domains/user.entity";
import { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import userService from "../domains/user.service";
import { showSuccess } from "helpers/toast";

const updateQueryName = "updateUser";
interface IUseUpdateUser {
  selectUser?: string[];
  newValues: Partial<User>[];
  handleQueryUser: () => void;
  listUser?: User[];
}

export const useUpdateUser = ({
  selectUser,
  newValues,
  handleQueryUser,
  listUser,
}: IUseUpdateUser) => {
  const updateUser = useCallback(async () => {
    if (!selectUser) return;
    for (const newValue of newValues) {
      if (newValue?.id && selectUser.includes(newValue?.id)) {
        await userService.updateUser(newValue);
      }
    }

    showSuccess("Cập nhật thông tin tài khoàn nhân viên thành công.");
  }, [newValues, selectUser]);

  useEffect(() => {
    listUser?.map((user) => {
      if (!user?.id) return user;
      if (selectUser?.includes(user?.id)) return user;
      const newValue = newValues.find((newValue) => user.id === newValue.id);
      if (newValue) return { ...user, ...newValue };
      return user;
    });
  }, [listUser, newValues, selectUser]);

  const { isLoading, refetch } = useQuery(updateQueryName, updateUser, {
    onSuccess() {
      handleQueryUser();
    },
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: 0,
  });

  const onUpdateUser = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    updating: isLoading,
    onUpdateUser,
  };
};
