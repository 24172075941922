import React, { FC } from "react";
import { Input } from "antd";

interface CustomInputProps {
  label?: string;
  name?: string;
  props: any;
}

const CustomInput: FC<CustomInputProps> = ({ label, ...props }) => {
  return (
    <Input
      {...props}
      style={{
        boxSizing: "border-box",
        padding: "0 10px",
        height: "46px",
        background: "#F9FAFB",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: "1px solid #AFBACA",
        borderRadius: 0,
        order: 1,
      }}
    />
  );
};

export default CustomInput;
