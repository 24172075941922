import CustomSelectHocs from "components/CustomFormSelect/CustomSelectHocs";
import "./styles.css";

interface CustomInputProps {
  mode?: "multiple" | "tags";
  options?: any[];
  [key: string]: any;
}

const CustomFormSelect = (props: CustomInputProps) => {
  return (
    <CustomSelectHocs
      {...props}
      className="custom-select"
      style={{
        boxSizing: "border-box",
        padding: 0,
        height: "46px",
        background: "#F9FAFB",
        borderBottom: "1px solid #AFBACA",
        flex: "none",
        order: 1,
        alignSelf: "stretch",
        flexGrow: 1,
      }}
    />
  );
};

export default CustomFormSelect;
