import { useCallback, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import positionService from "../domains/positions/position.service";
export const useQueryPositions = () => {
  const [pagination, setPagination] = useState(1);
  const { initialized } = useKeycloak();
  const { isLoading, error, data } = useQuery("positions", {
    queryFn: () => positionService.queryPositions(),
    enabled: initialized,
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const handleChangePagination = useCallback((current: number, size: number) => {
    setPagination(current);
  }, []);

  return { isLoading, error, data, pagination, handleChangePagination };
};
