import { useCallback, useEffect, useRef, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";
import userService from "../domains/user.service";

export const fetchQueryName = "fetchListUsers";
export const limit = 10;
const pageDefault = 0;

export const useFetchUserList = () => {
  const [pagination, setPagination] = useState(pageDefault);
  const keySearch = useRef<string>("");
  const { initialized } = useKeycloak();

  const { isLoading, isFetching, error, data, refetch } = useQuery(
    [fetchQueryName, pagination, limit, keySearch?.current],
    {
      queryFn: () => userService.fetchUserList(pagination, limit, keySearch?.current),
      enabled: initialized,
      cacheTime: 0,
      staleTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      retry: 2,
    }
  );

  const handleQueryUser = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (pagination > pageDefault) {
      refetch();
    }
  }, [pagination, refetch]);

  const handleChangePagination = useCallback((current: number) => {
    setPagination(current - 1);
  }, []);

  const onChangeKeySearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    keySearch.current = e.target.value;
    setPagination(pageDefault);
  }, []);

  return {
    limit,
    keySearch,
    isLoading,
    isFetching,
    error,
    data,
    pagination,
    handleChangePagination,
    onChangeKeySearch,
    handleQueryUser,
    refetch,
  };
};
