import { useState, useLayoutEffect } from "react";
import { screens } from "themes/default/screens";

export interface IDimension {
  width: number;
  height: number;
}

export const useWindowResize = () => {
  const [size, setSize] = useState<IDimension>({ width: 0, height: 0 });
  const isTablet = size.width < parseInt(screens.lg);
  const isMobile = size.width < parseInt(screens.md);

  useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { width: size.width, height: size.height, isTablet, isMobile };
};
