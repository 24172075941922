import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import userService from "../domains/user.service";
import { Space, Table, Tooltip } from "antd";
import { User } from "../domains/user.entity";
import { useConfirmModal } from "hooks/useConfirmModal";
import { CopyOutlined } from "@ant-design/icons";
import { useCopyText } from "utils/useCopyText";
import { showSuccess } from "helpers/toast";

interface IResetPassword {
  selectedUser?: User[];
}

interface IResetPasswordResult {
  username: string;
  newPassword: string;
}

function generateRandomString() {
  var specialChars = "!@#$%^&*()_-+=[]{}|:;<>?,./"; // All special characters
  var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // All uppercase letters
  var lowerChars = "abcdefghijklmnopqrstuvwxyz"; // All lowercase letters
  var digits = "0123456789"; // All digits
  var allChars = specialChars + upperChars + lowerChars + digits;

  var randomString = "";
  randomString += getRandomChar(specialChars);
  randomString += getRandomChar(upperChars);
  randomString += getRandomChar(lowerChars);
  randomString += getRandomChar(digits);

  for (var i = 4; i < 10; i++) {
    var randomIndex = Math.floor(Math.random() * allChars.length);
    randomString += allChars[randomIndex];
  }

  return randomString;
}

function getRandomChar(charSet: any) {
  var randomIndex = Math.floor(Math.random() * charSet.length);
  return charSet[randomIndex];
}

export const useResetPassword = ({ selectedUser }: IResetPassword) => {
  const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false);
  const [resetPasswordResult, setResetPasswordResult] = useState<IResetPasswordResult[]>([]);
  const resetPasswordResultRef = useRef<IResetPasswordResult[]>([]);
  const { copyTextToClipboard } = useCopyText();
  const [hideCancelButton, setHideCancelButton] = useState(false);
  const [confirmButtonText, setConfirmButtonText] = useState<string>("Xác nhận");

  const resetPasswordContent = useMemo(() => {
    const columns = [
      {
        title: "Mã nhân viên",
        dataIndex: "username",
        key: "username",
      },
      {
        title: "Mật khẩu mới",
        dataIndex: "newPassword",
        key: "newPassword",
      },
      {
        title: "",
        dataIndex: "action",
        key: "action",
        width: 50,
        render: (data: any, resetPasswordResult: IResetPasswordResult) => {
          return (
            <Tooltip title="Copy mã nhân viên và mật khẩu">
              <CopyOutlined
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyTextToClipboard(
                    `${resetPasswordResult.username}/${resetPasswordResult.newPassword}`,
                    "Sao chép mã nhân viên và mật khẩu thành công"
                  );
                }}
              />
            </Tooltip>
          );
        },
      },
    ];

    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <div className="font-semibold">Trạng thái đặt lại mật khẩu</div>
        <Table
          dataSource={resetPasswordResult}
          columns={columns}
          scroll={{ y: "200px" }}
          loading={isLoadingResetPassword}
        />
      </Space>
    );
  }, [copyTextToClipboard, isLoadingResetPassword, resetPasswordResult]);
  const {
    showModal: showModalResetPassword,
    hideModal: hideModalResetPassword,
    ConfirmModal: ResetPasswordModal,
    openModal: isResetPasswordModalOpen,
  } = useConfirmModal({
    onConfirm: async () => {
      if (hideCancelButton) {
        let copyText = "";
        resetPasswordResult.forEach((result) => {
          copyText += `${result.username}/${result.newPassword}\n`;
        });
        copyTextToClipboard(copyText, "Sao chép tất cả mã nhân viên và mật khẩu thành công");
      } else {
        resetPassword && resetPassword();
      }
    },
    onCancel: () => {
      hideModalResetPassword();
    },
    content: resetPasswordContent,
    title: "Đặt lại mật khẩu",
    isLoading: isLoadingResetPassword,
    confirmButtonText,
    hideCancelButton,
  });

  useEffect(() => {
    if (!isResetPasswordModalOpen) {
      resetPasswordResultRef.current = [];
      setResetPasswordResult([]);
      setHideCancelButton(false);
      setConfirmButtonText("Xác nhận");
    }
    if (!selectedUser) return;
    const resetPasswordResult: IResetPasswordResult[] = [];
    selectedUser?.forEach((user) => {
      if (user.username) {
        resetPasswordResult.push({
          username: user.username,
          newPassword: "",
        });
      }
    });

    resetPasswordResultRef.current = resetPasswordResult;
    setResetPasswordResult(resetPasswordResult);
  }, [selectedUser, isResetPasswordModalOpen]);

  const resetPassword = useCallback(async () => {
    if (!selectedUser) return;
    setIsLoadingResetPassword(true);
    setHideCancelButton(true);
    resetPasswordResultRef.current = [];
    for (const user of selectedUser) {
      const userId = user?.id;
      if (userId) {
        const newPassword = generateRandomString();
        try {
          await userService.resetPassword(userId, {
            temporary: true,
            type: "password",
            value: newPassword,
          });
          resetPasswordResultRef.current.push({
            username: user.username || "",
            newPassword,
          });
        } catch (error) {
          resetPasswordResultRef.current.push({
            username: user.username || "",
            newPassword: "Có lỗi xảy ra khi đặt lại mật khẩu, vui lòng liên hệ IT để được hỗ trợ",
          });
        }
      }
    }
    showSuccess("Đặt lại mật khẩu thành công");
    setResetPasswordResult(resetPasswordResultRef.current);
    setConfirmButtonText("Sao chép tất cả mã nhân viên và mật khẩu");
    setIsLoadingResetPassword(false);
  }, [selectedUser]);

  return { showModalResetPassword, resetPassword, ResetPasswordModal };
};
