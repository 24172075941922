import { showError, showSuccess } from "helpers/toast";

export const useCopyText = () => {
  const copyTextToClipboard = async (copyTextValue: string, successNotice?: string) => {
    try {
      if ("clipboard" in navigator) {
        await navigator.clipboard.writeText(copyTextValue);

        return showSuccess(successNotice || "Sao chép thành công!");
      }
      document.execCommand("copy", true, copyTextValue);

      return showSuccess(successNotice || "Sao chép thành công!");
    } catch (err) {
      return showError("Sao chép thất bại!");
    }
  };

  return { copyTextToClipboard };
};
