export const DATE_FORMAT = {
  DDMMYYY: "DD/MM/YYYY",
  DDMMYY_HHmm: "DD/MM/YYYY HH:mm",
  HHmm_DDMMYYYY: "HH:mm DD/MM/YYYY",
  DDMM: "DD/MM",
  fullDate: "DD/MM/YY HH:mm",
  YYYYMMDD: "YYYY-MM-DD",
  MMYYYY: "MM/YYYY",
  DD_MM_YYYY: "DD-MM-YYYY",
  YYYY_MM_DD: "YYYY-MM-DD",
  DD_MM_YY_HHmm: "DD-MM-YYYY HH:mm",
  DD_MM_YY_HHmmss: "DD-MM-YYYY HH:mm:ss",
  HH_MM_SS: "HH:mm:ss",
  HH_mm: "HH:mm",
  MM_DD_YYYY: "MM-DD-YYYY",
};
